import { Navigate, Route, Routes } from 'react-router-dom'
import { Conexion } from '../componentes/conexion/Conexion'
import { RecuperarClave } from '../componentes/conexion/RecuperarClave'
import { Portal } from '../componentes/portal/Portal'

const RoutesLogin = () => {

    return(
        <div>
            <Routes>
                <Route exact path="/conexion" element={ <Conexion/> } />
                <Route exact path="/recuperar-clave" element={ <RecuperarClave /> } />
                <Route exact path="/inicio" element={ <Portal /> } />
                <Route path="*" element={<Navigate to="/conexion" replace />} />
            </Routes>
        </div>
    )
}

export default RoutesLogin;
