import { IParametros } from '../interfaces/iParametros';
import { IResumenEjecutivo, ITablasRel, ITipoContratos, ITablas, IAplicacionesPermisos, ICGestion, IContratoConsultar, IPNaturalesConsultar, IEndpoint } from '../interfaces/iEndpoints';
import { K, KContratos, KEstado, KParametros, KPNatural, KV, NMes } from './constantes';
import { fetchTx } from './fetchTx';
import { servCorreoEnviar, servUsuario } from './servicios';
import { ICorreo } from '../interfaces/iCorreo';
import { fetchQS } from './fetchQS';

export interface IEstado {
  pEstado: number;
  pdte: boolean;
}

export const esMayorEdad = (d:string): boolean => {

  const dt = new Date();
  const referencia = new Date(dt.setFullYear(dt.getFullYear() - 18 ));
  const fechaInput = new Date(d);

  return (fechaInput <= referencia )
}

export const validaFechaContrato = (d:string, min:number, max:number): boolean => {

  const fechaInput = new Date(d);
  
  const dt_min = new Date();
  dt_min.setDate(dt_min.getDate() - min );
  const ref_min = new Date(dt_min);
  
  const dt_max = new Date();
  dt_max.setDate(dt_max.getDate() + max );
  const ref_max = new Date(dt_max);

  return ( (fechaInput >= ref_min) && (fechaInput <= ref_max) ) 
}

export const esRUTValido = (rut: string | null): boolean => {

  if (!rut) return false

  const text = rut.replace(/[^kK0-9]/g,"")

  if (text.length <= 0) return false

  const srut:string = text.substring(0, text.length - 1);
  const drut:string = text.substring(text.length - 1);

  var mul:number = 2;
  var sum:number = 0;

  for (var i = srut.length -1; i >= 0; i--) {
    sum = sum + parseInt(srut.charAt(i)) * mul;
    if (mul === 7) mul = 2;
    else mul++;
  }

  const res = sum % 11
  let dvr = '';

  switch (res) {
    case 1: { dvr = "k"; break }
    case 0: { dvr = "0"; break }
    default: { dvr = (11 - res).toString() }
  }

  return ( dvr === drut.toLowerCase() )
}

export const esCorreoValido = (email: string): boolean => {
/* /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)([[a-zA-Z0-9])+@([a-zA-Z0-9]+)+([a-zA-Z0-9-.]+)([a-zA-Z0-9]+)\.[a-zA-Z]{2,5})$/  */
  const match = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  return !!match;
};

export const pEstadosId = (estado:string):IEstado => { 
  let pEstado = 1;
  let pdte = true;
  switch (estado) {
    case KEstado._BORRADOR:            pEstado = pEstado+1; pdte = false; break;
    case KEstado._RECHAZADO:           pEstado = pEstado+2; pdte = false; break;
    case KEstado._ENVIADO_A_FIRMA_1:   pEstado = pEstado+3; pdte = true; break;
    case KEstado._FIRMA_1_OK:          pEstado = pEstado+4; pdte = true; break;
    case KEstado._FIRMA_PROVEEDOR_OK:  pEstado = pEstado+5; pdte = true; break;
    case KEstado._PENDIENTE:           pEstado = pEstado+6; pdte = false; break;
    case KEstado._FIRMA_2_OK:          pEstado = pEstado+7; pdte = false; break;
    case KEstado._TOTAL:               pEstado = pEstado+9; pdte = false; break;
    default:                           pEstado = -1;        pdte = false; break;
  }
  return {pEstado, pdte };
}

export const armarCross = (mData:IResumenEjecutivo[] ) => {

  var matriz:any = [];
  if (!mData || (mData.length < 0) ) return (matriz)

  var cges_id_act:string = '';
  var cges_id_ant:string = '';
  var nColsKey:number = 2;

  var fila:number = -1;
  var nroFilas:number = 0;
  var tLength:number = mData.length;
  var cantidad:number = 0;
  var numEstados:number = 8;
  var pPdte:number = 7;
  var pTotal:number = 9;
  var estado:IEstado;

  // matriz[fila] = [];
  for ( let i = 0; i < tLength; i++ ) {

    estado = pEstadosId( mData[i].esta_esta_id );
	  cantidad = Math.round( mData[i].cantidad );

	  cges_id_act = mData[i].cges_cges_id;
	  if (cges_id_ant === cges_id_act) {
    }
    else {
      cges_id_ant = cges_id_act;
      fila = fila + 1;
      matriz[fila] = [];
      matriz[fila][0] = mData[i].cges_cges_id;
      matriz[fila][1] = mData[i].cges_cges_nombre;

      for ( let ie = 0; ie < numEstados; ie++) {
        matriz[fila][ie+nColsKey] = 0;
      }
    }

    matriz[fila][estado.pEstado] = cantidad;
    // sumar pdtes, finalizados y total
    if (estado.pdte) matriz[fila][pPdte] += cantidad;
    matriz[fila][pTotal] += cantidad;
  }

  nroFilas = matriz.length;

  // total footer
  matriz[nroFilas] = [];
  matriz[nroFilas][0] = '...';
  matriz[nroFilas][1] = K._MSJE_TOTAL;
  // inicia en 0 antes de sumar
  for ( let ie=0; ie < numEstados; ie++) {
    matriz[nroFilas][ie+nColsKey] = 0;
  }
  // sumar en forma footer
  for ( let ii=0; ii < nroFilas; ii++) {
    for ( let ie=0; ie < numEstados; ie++) {
      matriz[nroFilas][ie+nColsKey] += matriz[ii][ie+nColsKey];
    }
  }

  return (matriz)
}

export const formatRUT = (rut: string | null) => {

  if (!rut) return ('')

  let nRut:string = rut;
  nRut = nRut.replace(/[^0-9kK]+/g, '').toUpperCase();
  if (rut.length <= 1) return rut

  let rutFormateado = `${nRut.slice(-4,-1)}-${nRut.substring(nRut.length -1)}`;
  for (let i = 4; i < nRut.length; i+=3)
    rutFormateado = `${nRut.slice(-3 - i,-i)}.${rutFormateado}`;

    return rutFormateado;
}

export const formatMonto = (moneda: string, monto:number, precision:number = 0, bRemate = false) => { //} (defined > 0, ! IsNAN ! null cantidad.legth > 0
//, minimunFractionDigits: precision, maximunFractionDigits: precision
  let salida = '';
  let simbolo = '';
  switch (moneda) {
    case K._MONE_ID_DEFAULT:
      simbolo = K._MONE_SIM_DEFAULT;
      salida = new Intl.NumberFormat(K._MONE_INT_DEFAULT, { style: 'decimal'} ).format(monto);
      break;
    default:
      simbolo = moneda;
      salida = new Intl.NumberFormat(K._MONE_INT_DEFAULT, { style: 'decimal'} ).format(monto);
      break;
  }

  salida = salida + ( bRemate ? K._REMATE_MONTO : '' );
  return `${simbolo}${salida}`;
  
}

export const formatFecha = (fecha:string, pHora:boolean = false, pSegs:boolean = false):string => {

  if (!fecha) return '';

  if (pHora)
    if (pSegs)
      return(`${fecha.slice(8,10)}-${fecha.slice(5,7)}-${fecha.slice(0,4)} ${fecha.slice(11,13)}:${fecha.slice(14,16)}:${fecha.slice(17,19)}`);
    else 
      return(`${fecha.slice(8,10)}-${fecha.slice(5,7)}-${fecha.slice(0,4)} ${fecha.slice(11,13)}:${fecha.slice(14,16)}`);
  else
    return(`${fecha.slice(8,10)}-${fecha.slice(5,7)}-${fecha.slice(0,4)}`);
}


export const limpiarRut = (rut:string|null):string => {

  if (!rut) return '';
  return(rut.trim().replace(/\./g,"").replace(/,/g,"").replace(/-/g,""));
}

export const tooltipAyuda = (campo:string, tAYuda:ITablasRel[]):string => {
  let ayuda = '';
  for (let index = 0; index < tAYuda.length; index++) {
    const element = tAYuda[index];
    if (element.tabh_tabl_elemento === campo) {
      ayuda = element.tabh_tabl_str1;
      break;
    }
  }
  return(ayuda);
}

export const rutFirmaTContrato = (tcon_id:string, firma: number, tTContrato: ITipoContratos[]):string|null => {
  let rut:string|null = null;
  for (let index = 0; index < tTContrato.length; index++) {
    const element = tTContrato[index];
    if (element.tcon_tcon_id === tcon_id) {
      switch (firma) {
        case 1: rut = (element.firma1_usua_rut) ? element.firma1_usua_rut : null; break;
        case 2: rut = (element.firma2_usua_rut) ? element.firma2_usua_rut : null; break;
      }
      break;
    }
  }
  return(rut);
}

export const tipoPnPjTContrato = (tcon_id:string, tTContrato: ITipoContratos[]):string => {
  let pnpj_id:string = K._PNPJ_ID_DEFAULT;
  for (let index = 0; index < tTContrato.length; index++) {
    const element = tTContrato[index];
    if (element.tcon_tcon_id === tcon_id) {
      pnpj_id = element.tcon_pnpj_id
      break;
    }
  }
  return(pnpj_id);
}

export const elementoTabla = (campo:string, tabla: ITablas[], caso:string):string => {
  let valor:string = `${K._MSJE_NOENCONTRADO} [${campo}]`;
  for (let index = 0; index < tabla.length; index++) {
    const element = tabla[index];
    if (element.tabl_tabl_elemento === campo) {
      switch (caso) {
        case K._TABLA_CAMPO_NOMBRE: valor = element.tabl_tabl_nombre; break;
        case K._TABLA_CAMPO_DESCRIPCION: valor = element.tabl_tabl_descripcion; break;
        case K._TABLA_CAMPO_STR1: valor = element.tabl_tabl_str1; break;
        case K._TABLA_CAMPO_STR2: valor = element.tabl_tabl_str2; break;
        case K._TABLA_CAMPO_STR3: valor = element.tabl_tabl_str3; break;
      }
      break;
    }
  }
  return(valor);
}


export const nombrePersona = (nombre1: string|null, nombre2: string|null, apellido1: string|null, apellido2: string|null, genero = '', bMayusculas = false, bPresentacion = false ):string => {

  let nombre = nombre1;
  nombre = nombre + ( nombre2 ? (' ' + nombre2) : '' );
  nombre = nombre + ' ' + apellido1;
  nombre = nombre + ( apellido2 ? (' ' + apellido2) : '' );

  let presentacion = ''
  switch (genero) {
    case K._FEMENINO: presentacion = K._FEMENINO_PRES + ' '; break;
    case K._MASCULINO: presentacion = K._MASCULINO_PRES + ' '; break;
  }

  if (bPresentacion) nombre = presentacion + nombre;
  if (bMayusculas) nombre = nombre.toUpperCase();

  return ( nombre);
}

export const nombreUsuario = (nombre1: string|null, apellido1: string|null, apellido2: string|null, alias: string|null,  ):string => {

  return ( ( alias ? alias : nombre1 ) + ' ' + apellido1 )
}

export const fechaTexto = (fecha: string, genero: string = '', bNacimiento: boolean = false ):string => {

  if (!fecha) return ('');

  const aaaa = fecha.slice(0,4);
  const mm = fecha.slice(5,7);
  const dd = fecha.slice(8,10);

  let fechaTexto = `${dd} de ${NMes[parseInt(mm)]} de ${aaaa}`;

  let presentacion = '';
  if (bNacimiento) {
    switch (genero) {
      case K._FEMENINO: presentacion = K._FEMENINO_FNAC + ' '; break;
      case K._MASCULINO: presentacion = K._MASCULINO_FNAC + ' '; break;
    }
    fechaTexto = presentacion + fechaTexto;
  }

  return (fechaTexto);
} 

export const errorSiNulo = ( campo:string | null): string => {

  return ( (!campo || campo.length === 0) ? (`<span style="color:white; background-color:red; font-weight:600">${K._MSJE_VACIO}</span>`) : campo);
}

export const listaCGestionAut = (tAplicacionesPermisos:IAplicacionesPermisos[], apps_id:string): string => {

  let cges_id_list = '';
  if (!tAplicacionesPermisos) return cges_id_list;
  for (let index = 0; index < tAplicacionesPermisos.length; index++) {
    const element = tAplicacionesPermisos[index];
    if (element.apps_apps_id === apps_id) {
        if (element.perm_perm_conjunto !== '*') {
          const la_cges_id = element.perm_perm_conjunto.split('|');
          for (let k = 0; k < la_cges_id.length; k++) {
            cges_id_list = cges_id_list + ( (k > 0) ? ',' : '') + la_cges_id[k].trim();
          }
        }
        break;
    }
  }
  return(cges_id_list);
} 

export const podarCGestion = (tCentrosGestion:ICGestion[], tAplicacionesPermisos:IAplicacionesPermisos[], apps_id: string ):ICGestion[] => {

  let tCentrosGestionAut:ICGestion[] = [];
  let indexCGAut = -1;
  for (let index = 0; index < tAplicacionesPermisos.length; index++) {
    const element = tAplicacionesPermisos[index];
    if (element.apps_apps_id === apps_id) {
        if (element.perm_perm_conjunto !== '*') {
          const la_cges_id = element.perm_perm_conjunto.split('|');
          for (let k = 0; k < la_cges_id.length; k++) {
            let cges_id_aut = la_cges_id[k].trim();
            for (let indexCG = 0; indexCG < tCentrosGestion.length; indexCG++) {
              const elementCG = tCentrosGestion[indexCG];
              if (elementCG.cges_cges_id === cges_id_aut) {
                indexCGAut++;
                tCentrosGestionAut[indexCGAut] = tCentrosGestion[indexCG];
              }
            }
          }
        }
        else {
          tCentrosGestionAut = tCentrosGestion;
          break;
        }
        break;
    }
  }
  return(tCentrosGestionAut);
}

export const listaCGestion = (tCentrosGestionAut:ICGestion[], cges_id_seleccion: string ):string => {

  let cges_id_lista = '';
  if (cges_id_seleccion !== K._TODO_ID) {
    cges_id_lista = cges_id_seleccion;
  }
  else {
    for (let k = 0; k < tCentrosGestionAut.length; k++) {
      const element = tCentrosGestionAut[k];
      cges_id_lista = cges_id_lista + ( (k > 0) ? ',' : '') + element.cges_cges_id;
    }
  }
  return(cges_id_lista)
}


export const consistenciaFechas = (fechaPpal:string, fechaIni:string, fechaFin:string):string => {

  let mensaje = '';

  if (fechaPpal.length === 0) mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KV._CTTO_MSJE_FECHA_PPAL_NODEF;
  if (fechaIni.length === 0)  mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KV._CTTO_MSJE_FECHA_INI_NODEF;
  if (fechaFin.length === 0)  mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KV._CTTO_MSJE_FECHA_FIN_NODEF;

  if (mensaje.length > 0) return mensaje;

  const dt_ppal = new Date(fechaPpal);
  const dt_ini  = new Date(fechaIni);
  const dt_fin  = new Date(fechaFin);
  
  const aaaa_ppal = fechaPpal.slice(0,4);
  const aaaa_ini  = fechaIni.slice(0,4);
  const aaaa_fin  = fechaFin.slice(0,4);

  if (dt_ppal > dt_ini) mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KV._CTTO_MSJE_PPAL_VS_INI;
  if (dt_ini  > dt_fin) mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KV._CTTO_MSJE_INI_VS_FIN;
  if ( (aaaa_ppal !== aaaa_ini) || (aaaa_ini !== aaaa_fin) ) mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KV._CTTO_MSJE_ANO_CALENDARIO;

  return mensaje;
}

export const validarDatosPersonas = (data:IContratoConsultar ):string => {

  let mensaje = '';

  if ( (!data.pnat_pnat_correo) || (data.pnat_pnat_correo.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_CORREO;
  if ( (!data.pnat_pnat_telefono) || (data.pnat_pnat_telefono.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_TELEFONO;
  if ( (!data.pnat_pnat_tx_cuenta_banco) || (data.pnat_pnat_tx_cuenta_banco.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_BANCO;
  if ( (!data.pnat_pnat_tx_cuenta_nro) || (data.pnat_pnat_tx_cuenta_nro.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_CUENTA_NRO;
  if ( (!data.pnat_pnat_tx_cuenta_tipo) || (data.pnat_pnat_tx_cuenta_tipo.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_CUENTA_TIPO;
  if ( (!data.pnat_pnat_genero) || (data.pnat_pnat_genero.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_GENERO;
  if ( (!data.pnat_pnat_fecha_nac) || (data.pnat_pnat_fecha_nac.length <= 0) )
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_FEC_NAC;
  if ( (!data.pnat_naci_id) || (data.pnat_naci_id.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_NACI_ID;
  if ( (!data.pnat_pnat_profesion) || (data.pnat_pnat_profesion.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_PROFESION;
  if ( (!data.pnat_pnat_direccion) || (data.pnat_pnat_direccion.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_DIRECCION;
  if ( (!data.pnat_comu_id) || (data.pnat_comu_id.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_COMU_ID;
  if ( (!data.pnat_regi_id) || (data.pnat_regi_id.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_REGI_ID;
  if ( (!data.pnat_pnat_decljurada_fecha) || (data.pnat_pnat_decljurada_fecha.length <= 0) || (!validaFechaDeclJurada(data.pnat_pnat_decljurada_fecha) ) )
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_DEL_JURADA;
  // if ( (!data.pnat_perf_id) || (data.pnat_perf_id.length <= 0) ) 
  //   mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + '- ';
  // if ( (!data.pnat_eciv_id) || (data.pnat_eciv_id.length <= 0) ) 
  //   mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_ECIV_ID:

  return mensaje;
}

export const validarDatosPersonasPN = (data:IPNaturalesConsultar|null ):string => {

  let mensaje = '';

  if (!data) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_SIN_DATOS;
  else {
  if ( (!data.pnat_pnat_correo) || (data.pnat_pnat_correo.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_CORREO;
  if ( (!data.pnat_pnat_telefono) || (data.pnat_pnat_telefono.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_TELEFONO;
  if ( (!data.pnat_pnat_tx_cuenta_banco) || (data.pnat_pnat_tx_cuenta_banco.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_BANCO;
  if ( (!data.pnat_pnat_tx_cuenta_nro) || (data.pnat_pnat_tx_cuenta_nro.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_CUENTA_NRO;
  if ( (!data.pnat_pnat_tx_cuenta_tipo) || (data.pnat_pnat_tx_cuenta_tipo.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_CUENTA_TIPO;
  if ( (!data.pnat_pnat_genero) || (data.pnat_pnat_genero.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_GENERO;
  if ( (!data.pnat_pnat_fecha_nac) || (data.pnat_pnat_fecha_nac.length <= 0) )
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_FEC_NAC;
  if ( (!data.pnat_naci_id) || (data.pnat_naci_id.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_NACI_ID;
  if ( (!data.pnat_pnat_profesion) || (data.pnat_pnat_profesion.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_PROFESION;
  if ( (!data.pnat_pnat_direccion) || (data.pnat_pnat_direccion.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_DIRECCION;
  if ( (!data.pnat_comu_id) || (data.pnat_comu_id.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_COMU_ID;
  if ( (!data.pnat_regi_id) || (data.pnat_regi_id.length <= 0) ) 
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_REGI_ID;
  if ( (!data.pnat_pnat_decljurada_fecha) || (data.pnat_pnat_decljurada_fecha.length <= 0) || (!validaFechaDeclJurada(data.pnat_pnat_decljurada_fecha) ) )
    mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_DEL_JURADA;
  }
  // if ( (!data.pnat_perf_id) || (data.pnat_perf_id.length <= 0) ) 
  //   mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + '- ';
  // if ( (!data.pnat_eciv_id) || (data.pnat_eciv_id.length <= 0) ) 
  //   mensaje = ( (mensaje.length > 0) ? `${mensaje}\n` : '') + KPNatural._PN_MSJE_V_PNAT_ECIV_ID:
  return mensaje;
}

export const validaFechaDeclJurada = (fechaStr:string): boolean => {

  if (!fechaStr || fechaStr.length < 0)  return false;

  const aaaaInput = parseInt( fechaStr.slice(0,4) );
  const fechaHoy = new Date();
  const aaaaHoy = fechaHoy.getFullYear();

  return ( aaaaInput === aaaaHoy ) 
}

export const pctjeMayusculas = (campo:string, vDefault:number = KV._PCTJE_MAYUSCULAS):boolean => {

  const upper = campo.match(/[A-Z]/g);
  const lUpper = (upper) ? upper.length : 0;
  const lCampo = (campo) ? campo.length : 0;

  if (lCampo === 0) 
    return true;
  else
    return ( ( ((lUpper / lCampo)*100) > vDefault ) ? false : true );
}

export const fParametros = (tParametros:ITablas[]):IParametros => {

  const valores:IParametros =   {
    ctto_rut_lmin: fValorParametroNum(KParametros.ctto_rut_lmin, tParametros, KV._RUT_LMIN),
    ctto_rut_lmax: fValorParametroNum(KParametros.ctto_rut_lmax, tParametros, KV._RUT_LMAX),
    ctto_fecha_ppal_dias_atras: fValorParametroNum(KParametros.ctto_fecha_ppal_dias_atras, tParametros, KV._CTTO_FECHA_PPAL_TOPE_MIN),
    ctto_fecha_ppal_dias_adelante: fValorParametroNum(KParametros.ctto_fecha_ppal_dias_adelante, tParametros, KV._CTTO_FECHA_PPAL_TOPE_MAX),
    ctto_fecha_ini_dias_atras: fValorParametroNum(KParametros.ctto_fecha_ini_dias_atras, tParametros, KV._CTTO_FECHA_INI_TOPE_MIN),
    ctto_fecha_ini_dias_adelante: fValorParametroNum(KParametros.ctto_fecha_ini_dias_adelante, tParametros, KV._CTTO_FECHA_INI_TOPE_MAX),
    ctto_fecha_fin_dias_atras: fValorParametroNum(KParametros.ctto_fecha_fin_dias_atras, tParametros, KV._CTTO_FECHA_FIN_TOPE_MIN),
    ctto_fecha_fin_dias_adelante: fValorParametroNum(KParametros.ctto_fecha_fin_dias_adelante, tParametros, KV._CTTO_FECHA_FIN_TOPE_MAX),
    ctto_nombre_lmin: fValorParametroNum(KParametros.ctto_nombre_lmin, tParametros, KV._CTTO_NOMBRE_LMIN),
    ctto_nombre_lmax: fValorParametroNum(KParametros.ctto_nombre_lmax, tParametros, KV._CTTO_NOMBRE_LMAX),
    ctto_objetivo_lmin: fValorParametroNum(KParametros.ctto_objetivo_lmin, tParametros, KV._CTTO_OBJETIVO_LMIN),
    ctto_objetivo_lmax: fValorParametroNum(KParametros.ctto_objetivo_lmax, tParametros, KV._CTTO_OBJETIVO_LMAX),
    ctto_descripcion_lmin: fValorParametroNum(KParametros.ctto_descripcion_lmin, tParametros, KV._CTTO_DESCRIPCION_LMIN),
    ctto_descripcion_lmax: fValorParametroNum(KParametros.ctto_descripcion_lmax, tParametros, KV._CTTO_DESCRIPCION_LMAX),
    ctto_desc_pago_lmin: fValorParametroNum(KParametros.ctto_desc_pago_lmin, tParametros, KV._CTTO_DESC_PAGO_LMIN),
    ctto_desc_pago_lmax: fValorParametroNum(KParametros.ctto_desc_pago_lmax, tParametros, KV._CTTO_DESC_PAGO_LMAX),
    ctto_observaciones_lmax: fValorParametroNum(KParametros.ctto_observaciones_lmax, tParametros, KV._CTTO_OBSERVACIONES_LMAX),
    ctto_mensajes_lmax: fValorParametroNum(KParametros.ctto_mensajes_lmax, tParametros, KV._CTTO_MENSAJES_LMAX),
    ctto_pcjte_mayusculas: fValorParametroNum(KParametros.ctto_pcjte_mayusculas, tParametros, KV._PCTJE_MAYUSCULAS),
    ctto_adjunto_extensiones_list: fValorParametroStr(KParametros.ctto_adjunto_extensiones_list, tParametros, KV._CTTO_ADJUNTO_EXTE_LIST),
    ctto_adjunto_extensiones_prov: fValorParametroStr(KParametros.ctto_adjunto_extensiones_prov, tParametros, KV._CTTO_ADJUNTO_EXTE_LIST),
    ctto_adjunto_size_max: fValorParametroNum(KParametros.ctto_adjunto_size_max, tParametros, KV._CTTO_ADJUNTO_SIZE_MAX),
    correo_soporte_para: fValorParametroStr(KParametros.correo_soporte_para, tParametros, ''),
    correo_soporte_cco: fValorParametroStr(KParametros.correo_soporte_cco, tParametros, ''),
    correo_soporte_lmin: fValorParametroNum(KParametros.correo_soporte_lmin, tParametros, KV._CTTO_NOMBRE_LMIN),
    correo_soporte_lmax: fValorParametroNum(KParametros.correo_soporte_lmax, tParametros, KV._CTTO_NOMBRE_LMAX),
    notif_ctto_pn_enviado_a_firma1: fValorParametroStr(KParametros.notif_ctto_pn_enviado_a_firma1, tParametros, ''),
    notif_ctto_pn_firma1_ok: fValorParametroStr(KParametros.notif_ctto_pn_firma1_ok, tParametros, ''),
    notif_ctto_pn_firmap_ok: fValorParametroStr(KParametros.notif_ctto_pn_firmap_ok, tParametros, ''),
    notif_ctto_pn_firma2_ok: fValorParametroStr(KParametros.notif_ctto_pn_firma2_ok, tParametros, ''),
    notif_ctto_rechazo: fValorParametroStr(KParametros.notif_ctto_rechazo, tParametros, ''),
    pnat_url_decl_jurada: fValorParametroStr(KParametros.pnat_url_decl_jurada, tParametros, ''),
  }
  return(valores);
}

export const fValorParametroNum = (campo:string, tParametros: ITablas[], vDefault:number):number => {
  let valor:number = vDefault;
  for (let index = 0; index < tParametros.length; index++) {
    const element = tParametros[index];
    if ( (element.tabl_tabl_elemento) && (element.tabl_tabl_elemento === campo) && (element.tabl_tabl_str1) ) {
      valor = parseInt(element.tabl_tabl_str1);
      break;
    }
  }
  return(valor);
}

export const fValorParametroStr = (campo:string, tParametros: ITablas[], vDefault:string):string => {
  let valor:string = vDefault;
  for (let index = 0; index < tParametros.length; index++) {
    const element = tParametros[index];
    if ( (element.tabl_tabl_elemento === campo) && (element.tabl_tabl_str1) ) {
      valor = element.tabl_tabl_str1.trim();
      break;
    }
  }
  return(valor);
}

export const truncarTexto = (texto: string | null, largo:number = 30): string => {
  if (!texto) return '';
  return ( (texto.length <= 0) ? '' : texto.substring(0, largo) );
}

export const nombrePersonaNatural = (nombre1: string, apellido1: string, apellido2: string|null): string => {
  return ( (apellido2) ? (`${nombre1} ${apellido1} ${apellido2.substring(0,1)}.`) : (`${nombre1} ${apellido1}`) );
}

export const validarInputFile = ( archivo:any, vParametros:IParametros ):boolean => {

  const [validaOK, ] = validarFile ( (archivo ? archivo[0] : null), vParametros );
  return(validaOK)
}
/*
export const validarInputFileProv = ( archivo:any, vParametros:IParametros, tipo:string, prefijo:string ):[boolean, string] => {

  var validaOK = false;
  var validaMsje = '';

  if (!archivo || (archivo.length === 0)) return ([validaOK, validaMsje]);

  if (tipo === K._TADJ_ID_DECL_JURADA) {
    [validaOK, validaMsje] = validarFileFirma ( (archivo ? archivo[0] : null), vParametros, prefijo );
  }
  else {
    [validaOK, validaMsje] = validarFile ( (archivo ? archivo[0] : null), vParametros );
  }

  return([validaOK, validaMsje]);
}
*/
export const validarInputFileFirma = ( archivo:any, vParametros:IParametros, prefijo:string ):boolean => {

  if (!archivo || (archivo.length === 0)) return true;
  const [validaOK, ] = validarFileFirma ( (archivo ? archivo[0] : null), vParametros, prefijo );
  return(validaOK)
}

export const validarFile = ( archivo:File|null, vParametros:IParametros ):[boolean, string] => {

  var validaOK = true;
  var validaMsje = '';

  if ( archivo ) {
    const sizeFile = archivo.size;
    const nameFile = archivo.name;
    const extFile = nameFile ? nameFile.split('.').pop() : null;
    const extList = vParametros.ctto_adjunto_extensiones_prov.split(',');
    var existeExt = false;
    if ( (!sizeFile) || (vParametros.ctto_adjunto_size_max <= sizeFile) ) {
      validaOK = false;
      validaMsje = KV._CTTO_MSJE_ADJUNTO_SIZE + (vParametros.ctto_adjunto_size_max / (1024*1024)) + 'MB';
    }
    if (extFile) {
      extList.forEach(element => {
        if (element.trim() === extFile.toLocaleLowerCase()) {
          existeExt = true;
        }
      });
      if (!existeExt) {
        validaOK = false;
        validaMsje = ((validaMsje.length > 0) ? `${validaMsje} ` : '') + KV._CTTO_MSJE_ADJUNTO_EXTENSION + extFile;
      }
    }
  }
  else {
    validaOK = false;
    validaMsje = KV._CTTO_MSJE_ADJUNTO_VACIO;
  }
  return [validaOK, validaMsje];
}

export const validarFileFirma = ( archivo:File|null, vParametros:IParametros, prefijo:string ):[boolean, string] => {

  var validaOK = true;
  var validaMsje = '';

  if ( archivo ) {
    const sizeFile = archivo.size;
    const nameFile = archivo.name;
    const extFile = nameFile ? nameFile.split('.').pop() : null;
    if ( (!sizeFile) || (vParametros.ctto_adjunto_size_max <= sizeFile) ) {
      validaOK = false;
      validaMsje = KV._CTTO_MSJE_ADJUNTO_SIZE + (vParametros.ctto_adjunto_size_max / (1024*1024)) + 'MB';
    }
    if (extFile) {
      if (KV._CTTO_ADJUNTO_EXTE_CTTO === extFile.toLocaleLowerCase()) {
        if (prefijo !== nameFile.substring(0,prefijo.length)) {
          validaOK = false;
          validaMsje = `${KContratos._SC_MSJE_ARCHIVO_PREFIJO} [${prefijo}] [${nameFile.substring(0,prefijo.length)}]`;
        }
      }
      else {
        validaOK = false;
        validaMsje = ((validaMsje.length > 0) ? `${validaMsje} ` : '') + KV._CTTO_MSJE_ADJUNTO_EXTENSION + extFile;
      }
    }
  }
  else {
    validaOK = false;
    validaMsje = KV._CTTO_MSJE_ADJUNTO_VACIO;
  }
  return [validaOK, validaMsje];
}

export const enviarCorreo = async ( data: ICorreo, ambiente: string|undefined ) => {

  servCorreoEnviar.params = {
    para_email: data.para_email,
    para_nombre: data.para_nombre,
    cc: data.cc,
    cco: data.cco,
    asunto: data.asunto,
    cuerpo: data.cuerpo,
  }

  const resp:IEndpoint = await fetchTx (servCorreoEnviar, null, ambiente);
  return (resp.code === 0) 
}

export const preparaNotificacion = (datos:IContratoConsultar, vParametros:IParametros, usuarioF1:any, usuarioF2:any, usuarioCG:any):ICorreo => {

  var correo:ICorreo = {
    para_email: usuarioF2.usua_usua_correo,
    para_nombre: nombreUsuario(usuarioF2.usua_usua_nombre1, usuarioF2.usua_usua_apellido1, usuarioF2.usua_usua_apellido2, usuarioF2.usua_usua_alias ),
    cc: '',
    cco: 'cmorist@gmail.com',
    asunto: KContratos._SC_NOTIF_ASUNTO_FIRMAR,
    cuerpo: vParametros.notif_ctto_pn_firmap_ok,
  }

  const mensaje = (datos.ctto_ctto_msje_interno ? datos.ctto_ctto_msje_interno : '');
  correo.cuerpo = reemplazaCuerpoCorreo (datos, correo.cuerpo, mensaje);
  return(correo);
}

export const preparaNotificacionRechazar = (datos:IContratoConsultar, vParametros:IParametros, mensaje:string, usuarioCG:any):ICorreo => {

  var correo:ICorreo = {
    para_email: usuarioCG.usua_usua_correo,
    para_nombre: nombreUsuario (usuarioCG.usua_usua_nombre1, usuarioCG.usua_usua_apellido1, usuarioCG.usua_usua_apellido2, usuarioCG.usua_usua_alias),
    cc: '',
    cco: '',
    asunto: KContratos._SC_NOTIF_ASUNTO_RECHAZAR,
    cuerpo: reemplazaCuerpoCorreo (datos, vParametros.notif_ctto_rechazo, mensaje),
  }
  return(correo);
}

export const reemplazaCuerpoCorreo = (datos:IContratoConsultar, cuerpo:string, mensaje:string):string => {

  //  {{ctto_id}}
  cuerpo = cuerpo.replace(/{{ctto_id}}/g, datos.ctto_ctto_id );
  //  {{ctto_nombre}}
  cuerpo = cuerpo.replace(/{{ctto_nombre}}/g, datos.ctto_ctto_nombre );
  //  {{ctto_fecha_ppal}}
  cuerpo = cuerpo.replace(/{{ctto_fecha_ppal}}/g, formatFecha (datos.ctto_ctto_fecha_ppal, false, false) );
  //  {{ctto_fecha_ini}}
  cuerpo = cuerpo.replace(/{{ctto_fecha_ini}}/g, formatFecha (datos.ctto_ctto_fecha_ini, false, false) );
  //  {{ctto_fecha_fin}}
  cuerpo = cuerpo.replace(/{{ctto_fecha_fin}}/g, formatFecha (datos.ctto_ctto_fecha_fin, false, false) );
  // {{pnat_rut}}
  cuerpo = cuerpo.replace(/{{pnat_rut}}/g, formatRUT (datos.pnat_pnat_rut) );
  // {{pnat_nombre}}
  cuerpo = cuerpo.replace(/{{pnat_nombre}}/g, nombrePersona ( datos.pnat_pnat_nombre1, datos.pnat_pnat_nombre2, datos.pnat_pnat_apellido1, datos.pnat_pnat_apellido2, '', false, false ))
  // {{cges_nombre}}
  cuerpo = cuerpo.replace(/{{cges_nombre}}/g, datos.cges_cges_nombre );
  //  {{ctto_mensaje}}
  cuerpo = cuerpo.replace(/{{ctto_mensaje}}/g, mensaje );

  return(cuerpo);
}

export const leerUsuario = async (usua_id:string, ambiente:string|undefined) => {

  servUsuario.params = {
    ...servUsuario.params,
    usua_id: usua_id,
  }
  
  const respPN:IEndpoint = await fetchQS (servUsuario, null, ambiente)
  if (respPN.code === 0) {
    if (respPN.data.length > 0) {
      const datos = respPN.data[0];
      return(datos)
    }
  }
  return ({});
}
