import Cookies from "js-cookie";
import { K } from "./constantes";

export const fetchSubirAdjuntoProv = async ( servicio, ambiente, data ) => {

    const d = new Date();
    const d_str = d.toJSON();

    const nameFile = data.adju_path.name;
    const extFile = nameFile ? nameFile.split('.').pop() : null;

    const formData = new FormData();

    formData.append("pnpj_id", data.pnpj_id);
    formData.append("rut", data.rut);
    formData.append("tadj_id", data.tadj_id);
    formData.append("padj_nombre", `${data.rut}_${d_str}.${extFile}`);
    formData.append("padj_descripcion", data.adju_descripcion);
    formData.append("adjunto", data.adju_path);
    formData.append("firmado", data.firmado);

    const url = `${ambiente}${servicio.endPoint}`;

    const headers = new Headers();

    var respServicio = {
        code: 0,
        data: ''
    } 

    if ( servicio.usaToken ) {
        const jwt = Cookies.get ("jwt");
        if (!jwt) {
            respServicio = {
                code: -1,
                data: '!JWT'
            } 
            return respServicio;
        }
        headers.append ( 'Authorization', `Bearer ${jwt}`);
    }

    const header = {
        method: servicio.metodo,
        body: formData,
        headers
    }

    await fetch ( url, header )

    .then ( async (resp) => {
        if (resp.ok) {
            const data = await resp.json();
            respServicio = {
                code: 0,
                data: data.data
            } 
        } else {
            return await resp.text().then ( text => { throw new Error(text) })
        }
    })

    .catch ( (error) => {
// console.log('-----error.message----', error.message)
        const { code, message } = JSON.parse(error.message);
        respServicio = {
            code:(((!code) ||(code===0))?K._CODE_ERROR_500_API:code),
            data:message,
        } 
    })

    return respServicio;
} 
