import { AuthState } from './';
import { IPNaturalContext } from '../../interfaces/iPNaturalContext';

type AuthActionType = 
| { type: 'login', payload: IPNaturalContext } 
| { type: 'nuevo_token', payload: IPNaturalContext } 
| { type: 'logout' } 
| { type: 'ambiente', payload: string } 

export const authReducer = ( state: AuthState, action: AuthActionType ): AuthState => {

   switch (action.type) {
        case 'ambiente':
            return {
                ...state,
                ambiente: action.payload,
            }
       case 'login':
            return {
                ...state,
                conectado: true,
                usuario: action.payload,
            }

        case 'nuevo_token':
            return {
                ...state,
                conectado: true,
                usuario: action.payload,
            }

        case 'logout':
            return {
                ...state,
                conectado: false,
                usuario: undefined,
            }

       default:
          return state;
   }

}