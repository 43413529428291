import { FC, useState, useEffect } from 'react';
import { IProveedorAdjuntos } from '../../interfaces/iEndpoints';
import { K, KErroresDescargarProveedorAdjunto, KPNatural } from '../../utils/constantes';
import { servProveedorAdjuntoConsultar, servProveedorAdjuntoDescargar } from '../../utils/servicios';
import { formatFecha } from '../../utils/utilidades';
import { fetchQSAdjuntosProv } from '../../utils/fetchQSAdjuntosProv';
import { fetchDescargarAdjuntoProv } from '../../utils/fetchDescargarAdjuntoProv';
import { 
  useColorModeValue, 
  Tr, 
  Td, 
  TableContainer, 
  Table, 
  Thead, 
  Th, 
  Box, 
  Tbody, 
  Button, 
  Text,   
  useToast,
} from '@chakra-ui/react';

interface Props {
    rut: string;
    ambiente: string | undefined;
    actualizar: number;
    setActualizar: React.Dispatch<React.SetStateAction<number>>;
}

const AdjuntosConsulta: FC<Props> = ({ rut, ambiente, actualizar, setActualizar }) => {

  const [ errorDetectado, setErrorDetectado ] = useState('');
  const [ cargando, setCargando ] = useState(false);
  const [ datos, setDatos ] = useState([]);

  const toast       = useToast();

  useEffect(() => {

    fetchQSAdjuntosProv (servProveedorAdjuntoConsultar, ambiente, K._PNPJ_ID_PNATURAL, rut, null)
    .then ( resp => {
      if (resp.code !== 0) {
        setErrorDetectado(KPNatural._PN_AD_MSJE_ERROR_BITACORA);
      }
      else if (resp.data.length > 0) {
        const datosR:any = resp.data;
        setDatos(datosR);
      } 
      setCargando(false);
    })

    .catch ( (error) => {
        const { message } = JSON.parse(error.message);
        setErrorDetectado(message);
        setCargando(false);
    })    

  }, [actualizar, ambiente, rut])
  
  const colorBG = useColorModeValue("white", "gray.700");
  const colorBG2  = useColorModeValue("gray.100", "gray.600")

  const handleOnClick = (e: React.SyntheticEvent):void => {

    const [padj_id, padj_nombre] = e.currentTarget.id.split('|');

    fetchDescargarAdjuntoProv (servProveedorAdjuntoDescargar, ambiente, K._PNPJ_ID_PNATURAL, rut, padj_id, padj_nombre)
    .then ( resp => {
      if (resp.code !== 0) {

        const errorDescargar = KErroresDescargarProveedorAdjunto.filter (elemento => (elemento.codigo ===  resp.code) );
        const errorMensaje = (errorDescargar.length > 0) ? errorDescargar[0].mensaje : `[${resp.code} // ${resp.data}]`;

        toast({
          title: KPNatural._PN_AD_MSJE_TITULO_FRACASO_DESCARGAR,
          description: errorMensaje,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
      else {

        toast({
          title: KPNatural._PN_AD_MSJE_TITULO_EXITO,
          description: 'errorMensaje',
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    })

    .catch ( (error) => {
      toast({
        title: KPNatural._PN_AD_MSJE_TITULO_FRACASO_DESCARGAR,
        description: KPNatural._PN_AD_MSJE_DESCARGAR_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    })    

  }

  if (errorDetectado) {
    return (    <Text>{errorDetectado}</Text>    )
  }
  else if (cargando) {
    return (    <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />    )
  }
  else {
    return(
      <Box
        bg={colorBG}
        w='3xl' 
        p='0'
      >
        <TableContainer>
          <Table colorScheme='teal' size="sm">
              <Thead>
                <Tr bg={colorBG2} >
                  <Th></Th>
                  <Th>{KPNatural._PN_AD_TIPO}</Th>
                  <Th>{KPNatural._PN_AD_DESCRIPCION}</Th>
                  <Th>{KPNatural._PN_AD_FECHA}</Th>
                  <Th>{KPNatural._PN_AD_DESCARGAR}</Th>
                </Tr>
              </Thead>
              <Tbody>
              {
                ( (!datos) || (datos.length <= 0) )
                ?
                (<Tr key={1}>
                  <Td colSpan={5}>
                    <h1>{K._NOHAYDATOS}</h1>
                  </Td>
                </Tr>)
                :
                (
                  datos.map((fila:IProveedorAdjuntos, index:number) => (
                    (
                      <Tr key={index}>
                          <Td>{index+1}</Td>
                          <Td>{fila.padj_tadj_id}</Td>
                          <Td>{fila.padj_padj_descripcion}</Td>
                          <Td>{formatFecha(fila.padj_padj_fecha_creacion, true, true)}</Td>
                          <Td><Button 
                                variant='outline' 
                                onClick={handleOnClick} 
                                size={'xs'} 
                                id={`${fila.padj_padj_id}|${fila.padj_padj_nombre}`} 
                                value={fila.padj_padj_id} 
                                title={fila.padj_padj_id}
                              >
                                {KPNatural._PN_AD_BOTON_DESCARGAR}
                              </Button>
                          </Td>
                      </Tr>
                    )
                  ))
                )
              }
              </Tbody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default AdjuntosConsulta;
