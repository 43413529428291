import { createContext } from 'react';
import { IPNaturalContext } from '../../interfaces/iPNaturalContext';

interface ContextProps {
    conectado: boolean;
    usuario?: IPNaturalContext;
    login: (usuario: string, clave: string) => Promise<boolean>;
    logout: () => void;
    renovarToken: () => Promise<boolean>;
    ambiente?: string;
}

export const AuthContext = createContext({} as ContextProps );
