import { AuthProvider } from "./context"
import { BrowserRouter } from "react-router-dom"
import { Inicio } from "./componentes/conexion/Inicio"
import {
  ChakraProvider,
} from "@chakra-ui/react"

export const App = () => {

  return(
    <AuthProvider>
      <ChakraProvider>
          <BrowserRouter>
            <Inicio/> 
          </BrowserRouter>
      </ChakraProvider>
    </AuthProvider>
  )
}
