import * as React from 'react';
import { useState, useContext } from 'react';
import { AuthContext } from '../../context';
import { useForm } from 'react-hook-form'
import { Link as LinkRouter  } from 'react-router-dom'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { KConexion, KV } from '../../utils/constantes';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    PinInput,
    PinInputField,
    Stack,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'
import { servClaveModificar, servClaveRecuperar } from '../../utils/servicios';
import { IEndpoint } from '../../interfaces/iEndpoints';
import { fetchTx } from '../../utils/fetchTx';
import { limpiarRut } from '../../utils/utilidades';

type FormData = {
  rut: string,
  clave: string,
  clave2: string,
  pin_1: number,
  pin_2: number,
  pin_3: number,
  pin_4: number,
  pin_5: number,
  pin_6: number,
};
   
export const RecuperarClave = () => {

  const { ambiente } = useContext( AuthContext );

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<FormData>();

    const [ exitoPin, setExitoPin ] = useState(false);
    const [ exitoClave, setExitoClave ] = useState(false);
    const [ showAlert, setShowAlert ] = useState(false);
    const [ statusSuccess, setStatusSucess ] = useState(false);
    const [ messageAlert, setMessageAlert ] = useState('');
    const [ cambiarClave, setCambiarClave ] = useState(false);
    
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = React.useRef<HTMLInputElement>(null)

    const onClickReveal = () => {
      onToggle()
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true })
      }
    }

    const validarClave = (str:string) => {
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
      return re.test(str);
    }

    const onSubmit = async( { rut }: FormData ) => {

        setShowAlert(false);

        servClaveRecuperar.params = {
          caso: KConexion._RCLV_CASO_PNATURAL,
          rut: limpiarRut(rut),
        }

       const resp:IEndpoint = await fetchTx (servClaveRecuperar, null, ambiente);

       if (resp.code === 0) {
        const datosR:any = resp.data;
        setShowAlert(true)
        setStatusSucess(true)
        setMessageAlert(`${KConexion._RCLV_MSJE_PIN_INGRESAR} ${datosR.correo}`)
        setCambiarClave(true);
        setExitoPin(true);
      }
      else {
        setShowAlert(true);
        setStatusSucess(false);
        setMessageAlert(KConexion._RCLV_MSJE_PIN_ERROR);
        setExitoPin(false);
      }
    }

    const onCambiarClave = async( data:FormData ) => {

      if (data.clave !== data.clave2) {
        setShowAlert(true);
        setStatusSucess(false);
        setMessageAlert(KConexion._RCLV_MSJE_CLAVE_ERROR);
      }
      else {
        servClaveModificar.params = {
          caso: KConexion._RCLV_CASO_PNATURAL,
          rut: limpiarRut(data.rut),
          pin: `${data.pin_1}${data.pin_2}${data.pin_3}${data.pin_4}${data.pin_5}${data.pin_6}`,
          clave: data.clave
        }

        const resp:IEndpoint = await fetchTx (servClaveModificar, null, ambiente);

        if (resp.code === 0) {
          setExitoClave(true)
          setShowAlert(true);
          setStatusSucess(true);
          setMessageAlert(KConexion._RCLV_MSJE_CLAVE_OK);
        }
        else {
          setExitoClave(false)
          setShowAlert(true)
          setStatusSucess(false)
          setMessageAlert(KConexion._RCLV_MSJE_CLAVE_ERROR_SERV)
        }
    }
  }

   return (

      <Container 
        maxW="container.2xl" 
        p={5}
        bg={useColorModeValue('gray.100', 'gray.700')}
      >
        <Flex
          h={{ base: 'auto', md: '110vh' }}
          py={[0, 5, 5, 5]}
          direction={{ base: 'column-reverse', md: 'row' }}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          <Container 
            maxW="lg" 
            py={{ base: '4', md: '8' }} 
            px={{ base: '0', sm: '8' }} 
            bg={useColorModeValue('gray.100', 'gray.700')}
            borderRadius={{ base: 'none', sm: 'md' }}
            >
            <Stack spacing="4">
              <Box
                py={{ base: '0', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                borderRadius={{ base: 'none', sm: 'xl' }}
              >
                <form onSubmit={ handleSubmit(onSubmit) } noValidate>
                  <Stack spacing="4">
                    <Stack spacing="4">

                    {/* ================ RUT =================================*/}
                    <FormControl isInvalid={errors.rut?true:false} isRequired>
                        <FormLabel htmlFor="rut"  fontSize={'md'}>{KConexion._RCLV_FORM_LABEL_RUT}</FormLabel>
                        <Input 
                          id="rut" 
                          type="text" 
                          {...register('rut', {
                            required: KConexion._CONX_MSJE_INGR_USUARIO,
                            minLength: { value: KConexion._CONX_USUARIO_LMIN, message: `${KV._LARGO_MIN} ${KConexion._CONX_USUARIO_LMIN}` },
                            maxLength: { value: KConexion._CONX_USUARIO_LMAX, message: `${KV._LARGO_MAX} ${KConexion._CONX_USUARIO_LMAX}` },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.rut && errors.rut.message}
                        </FormErrorMessage>
                      </FormControl>

                    </Stack>

                    {/* ================ BOTON PIN  ===============================*/}
                    {
                      !exitoPin &&
                      (
                        <Stack spacing="6">
                          <Button 
                            type="submit" 
                            onClick={ handleSubmit(onSubmit) }
                            isLoading={isSubmitting}
                          >
                            {KConexion._RCLV_FORM_BOTON_PIN}
                          </Button>
                        </Stack>
                      )
                    }

                    {/* ================ FORM CAMBIO CLAVE =================================*/}
                    { 
                      cambiarClave && 
                      (
                        <VStack spacing="3">

                          {/* ================ PIN =================================*/}
                          <FormControl >
                            <Stack
                              direction={{ base: 'row', md: 'row' }}
                              spacing={{ base: '0', md: '0' }}
                              justify="space-between"
                            >
                              <FormLabel fontSize={'xs'}>{KConexion._RCLV_FORM_LABEL_PIN}</FormLabel>

                              <HStack spacing={'1'}>
                                <PinInput >
                                  <PinInputField {...register('pin_1', {required: '*'})} />
                                  <PinInputField {...register('pin_2', {required: '*'})} />
                                  <PinInputField {...register('pin_3', {required: '*'})} />
                                  <PinInputField {...register('pin_4', {required: '*'})} />
                                  <PinInputField {...register('pin_5', {required: '*'})} />
                                  <PinInputField {...register('pin_6', {required: '*'})} />
                                </PinInput>
                              </HStack>
                            </Stack>
                          </FormControl>

                          {/* ================ CLAVE NUEVA =================================*/}
                          <FormControl isInvalid={errors.clave?true:false} isRequired>
                            <Stack
                              direction={{ base: 'row', md: 'row' }}
                              spacing={{ base: '1.5', md: '8' }}
                              justify="space-between"
                            >
                              <Text fontSize={'xs'}>{KConexion._RCLV_FORM_LABEL_CLAVE}</Text>
                              <InputGroup w={'80'}>
                                <InputRightElement>
                                  <IconButton
                                    variant="link"
                                    aria-label={isOpen ? KConexion._CONX_CLAVE_OCULTAR : KConexion._CONX_CLAVE_MOSTRAR }
                                    icon={isOpen ? <HiEyeOff /> : <HiEye />}
                                    onClick={onClickReveal}
                                  />
                                </InputRightElement>
                                <Input
                                  id="clave"
                                  type={isOpen ? 'text' : 'password'}
                                  {...register('clave', {
                                    required:  KConexion._RCLV_MSJE_INGR_CLAVE,
                                    minLength: { value: KConexion._CONX_CLAVE_LMIN, message: `${KV._LARGO_MIN} ${KConexion._CONX_CLAVE_LMIN}` },
                                    maxLength: { value: KConexion._CONX_CLAVE_LMAX, message: `${KV._LARGO_MAX} ${KConexion._CONX_CLAVE_LMAX}` },
                                    validate: { validarClaves: (v) => validarClave (v) || KConexion._RCLV_FORM_MSJE_CLAVE},
                                  })}
                                />
                              </InputGroup>
                            </Stack>
                            <FormErrorMessage fontSize={'xs'}>
                              {errors.clave && errors.clave.message}
                            </FormErrorMessage>
                          </FormControl>

                          {/* ================ CLAVE REPETIR =================================*/}
                          <FormControl isInvalid={errors.clave2?true:false} isRequired>
                            <Stack
                              direction={{ base: 'row', md: 'row' }}
                              spacing={{ base: '1.5', md: '8' }}
                              justify="space-between"
                            >
                              <Text fontSize={'xs'}>{KConexion._RCLV_FORM_MSJE_CLAVE2}</Text>
                              <InputGroup w={'80'}>
                                <InputRightElement>
                                  <IconButton
                                    variant="link"
                                    aria-label={isOpen ? KConexion._CONX_CLAVE_OCULTAR : KConexion._CONX_CLAVE_MOSTRAR }
                                    icon={isOpen ? <HiEyeOff /> : <HiEye />}
                                    onClick={onClickReveal}
                                  />
                                </InputRightElement>
                                <Input
                                  id="clave2"
                                  type={isOpen ? 'text' : 'password'}
                                  {...register('clave2', {
                                    required:  KConexion._RCLV_MSJE_INGR_CLAVE2,
                                    minLength: { value: KConexion._CONX_CLAVE_LMIN, message: `${KV._LARGO_MIN} ${KConexion._CONX_CLAVE_LMIN}` },
                                    maxLength: { value: KConexion._CONX_CLAVE_LMAX, message: `${KV._LARGO_MAX} ${KConexion._CONX_CLAVE_LMAX}` },
                                    validate: { validarClaves: (v) => validarClave (v) || KConexion._RCLV_FORM_MSJE_CLAVE },
                                  })}
                                />
                              </InputGroup>
                            </Stack>
                            <FormErrorMessage fontSize={'xs'}>
                              {errors.clave2 && errors.clave2.message}
                            </FormErrorMessage>
                          </FormControl>

                          {/* ================ BOTON CAMBIAR CLAVE =================================*/}
                          {
                            !exitoClave &&
                            (
                              <Stack spacing="6">
                                <Button 
                                  type="submit" 
                                  onClick={ handleSubmit(onCambiarClave) }
                                  isLoading={isSubmitting}
                                >
                                  {KConexion._RCLV_FORM_BOTON_CAMBIAR}
                                </Button>
                              </Stack>
                            )
                          }
                          
                        </VStack>
                      )
                    }

                    {/* ================ MENSAJE =================================*/}
                    { 
                      showAlert && 
                      (
                        <Stack spacing={'4'}>
                          <Alert status={(statusSuccess)?'success':'error'}>
                            <AlertIcon />
                            <AlertDescription>{messageAlert}</AlertDescription>
                          </Alert>
                        </Stack>
                      )
                    }

                  </Stack>
                </form>


                {/* ================ link volver =================================*/}
                <HStack justify="space-between">
                  <LinkRouter to="/conexion">
                      <Button 
                        variant="link" 
                        colorScheme="blue" 
                        size="sm"
                        justifyContent="start" 
                        id="b_datos" 
                        p={'5'}
                      >
                        {KConexion._RCLV_FORM_BOTON_VOLVER}
                      </Button>
                  </LinkRouter>
                </HStack>

              </Box>
            </Stack>
          </Container>
        </Flex>
      </Container>
    )
  }
