import { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TablasContext } from '../../context/TablasContext';
import { IAdjuntosForm, IEndpoint, ITablas } from '../../interfaces/iEndpoints';
import { K, KErroresSubirAdjuntoProv, KPNatural, KV } from '../../utils/constantes';
import { 
  Button, 
  Container, 
  Divider, 
  useColorModeValue, 
  VStack, 
  HStack, 
  FormControl, 
  FormLabel, 
  Select, 
  FormErrorMessage, 
  Text, 
  Input,
  useToast,
  Textarea
} from '@chakra-ui/react';
import { servProveedorAdjuntoSubir } from '../../utils/servicios';
import { fParametros, validarFile, validarFileFirma, validarInputFile } from '../../utils/utilidades';
import { fetchSubirAdjuntoProv } from '../../utils/fetchSubirAdjuntoProv';

interface Props {
    rut: string;
    ambiente: string | undefined;
    actualizar: number;
    setActualizar: React.Dispatch<React.SetStateAction<number>>;
}

interface Datos {
  pnpj_id: string;
  rut: string;
  tadj_id: string;
  adju_nombre: string;
  adju_descripcion: string;
  adju_path: string | Blob;
  firmado: string;
}

const AdjuntosForm: FC<Props> = ({ rut, ambiente, actualizar, setActualizar }) => {

  const { tTipoAdjunto, tParametros } = useContext( TablasContext );
  const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<IAdjuntosForm>();
  const [ mostrarMensajeError, setMostrarMensajeError ] = useState(false);
  const [ mensajeActualizar, setMensajeActualizar ] = useState('');
  
  const toast = useToast();

  const colorBG = useColorModeValue("white", "gray.700");
  const colorLabel = useColorModeValue("blue", "whiteAlpha.900");

  const bgInput       = useColorModeValue("white", "gray.600");
  const readOnlyInput = false;

  const colorBGErrores    = useColorModeValue("pink.100", "pink.200");
  const colorTextMensajes  = useColorModeValue("gray.800", "gray.800");

  const vParametros = fParametros(tParametros);

  const handleChangeFile = (e:React.FormEvent<HTMLInputElement>) => {
    const [validaOK, validaMsje] = validarFile ( (e.currentTarget.files ? e.currentTarget.files[0] : null), vParametros );
    if (!validaOK) {
      toast({
        title: KV._CTTO_MSJE_ADJUNTO_AVISO,
        description: validaMsje,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  const onSubmit = async ( data:IAdjuntosForm ) => {

    var datos:Datos = {
      pnpj_id: K._PNPJ_ID_PNATURAL,
      rut: rut,
      tadj_id: '?',
      adju_nombre: '?',
      adju_descripcion: '?',
      adju_path: '?',
      firmado: K._NO,
    }
    var bSubir = false;
    var validaOK = true;
    var validaMsje = '';
  
    if ( (data.tadj_id_1 !== K._SELE_ID) && (data.adju_path_1) ) {

      if (data.tadj_id_1.substring(0,K._TADJ_ID_DECL_JURADA.length) === K._TADJ_ID_DECL_JURADA) {
        datos.firmado = K._SI;
        const archivo:any =  data.adju_path_1[0];
        const tDeclJurada:ITablas[] =  tTipoAdjunto.filter (adjunto => (adjunto.tabl_tabl_elemento.substring(0,K._TADJ_ID_DECL_JURADA.length) === K._TADJ_ID_DECL_JURADA ) );
        const [archivo_dj_nombre, ] = tDeclJurada[0].tabl_tabl_str1.split('.');

        [validaOK, validaMsje] = validarFileFirma ( archivo, vParametros, archivo_dj_nombre );
      }

      if (validaOK) {
        datos = {
          ...datos,
          tadj_id: data.tadj_id_1,
          adju_nombre: '',
          adju_descripcion: data.adju_descripcion_1,
          adju_path: data.adju_path_1[0],
        }
        bSubir = true;
      }
      else {
        setMensajeActualizar(`${KPNatural._PN_AD_MSJE_ADJUNTAR_FRACASO} // ${validaMsje}`);
        setMostrarMensajeError(true);
        toast({
          title: KPNatural._PN_AD_MSJE_TITULO_FRACASO,
          description: `${KPNatural._PN_AD_MSJE_ADJUNTAR_FRACASO} // ${validaMsje}`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    }     

    if (bSubir) {

      const resp:IEndpoint = await fetchSubirAdjuntoProv ( servProveedorAdjuntoSubir, ambiente, datos );

      if (resp.code === 0) {
        toast({
          title: KPNatural._PN_AD_MSJE_TITULO_EXITO,
          description: `${KPNatural._PN_AD_MSJE_ADJUNTAR_EXITO}`,
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
        setMensajeActualizar('');
        setMostrarMensajeError(false);
      }
      else {
        
        const errorSubir = KErroresSubirAdjuntoProv.filter (elemento => (elemento.codigo ===  resp.code) );
        const errorMensaje = (errorSubir.length > 0) ? errorSubir[0].mensaje : `[${resp.code} // ${resp.data}]`;
        
        setMensajeActualizar(errorMensaje);
        setMostrarMensajeError(true);

        toast({
          title: KPNatural._PN_AD_MSJE_TITULO_FRACASO,
          description: errorMensaje,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    }

    setActualizar(actualizar+1);
    setValue('tadj_id_1', K._SELE_ID);
    setValue('adju_descripcion_1', '');
    setValue('adju_path_1', '');

  }

  return(
    <Container 
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '4' }} 
        maxW='8xl' 
        bg={colorBG}
    >
      <VStack spacing="3">

        <form onSubmit={ handleSubmit(onSubmit) }  >

          <VStack p="5">

            <HStack>
              { /* adjunto_tipo ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.tadj_id_1?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_AD_TIPO}</FormLabel>
                <Select 
                  size={'sm'} 
                  defaultValue={K._SELE_ID} 
                  bg={bgInput}
                  {...register('tadj_id_1', {
                    validate: { seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_AD_TIPO_SEL} ` }
                  })} 
                >
                  <option key='tadj_id_1_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                  {
                    tTipoAdjunto.map( adjunto => (
                      <option key={adjunto.tabl_tabl_elemento} value={adjunto.tabl_tabl_elemento} >{adjunto.tabl_tabl_nombre}</option>
                    ))
                  }  
                </Select>
                <FormErrorMessage fontSize="10">
                  {errors.tadj_id_1 && errors.tadj_id_1.message}
                </FormErrorMessage>
              </FormControl>


              { /* adjunto_nombre ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_descripcion_1?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KPNatural._PN_AD_DESCRIPCION}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  bg={bgInput}
                  defaultValue={''} 
                  readOnly={readOnlyInput}
                  size='sm'
                  { ...register('adju_descripcion_1', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value:   5, message: `${KV._LARGO_MIN} 5`   },
                      maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                    })
                  } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_descripcion_1 && errors.adju_descripcion_1.message}
                </FormErrorMessage>
              </FormControl>

              { /* file ----------------------------------------------------------- */ }
              <FormControl isInvalid={errors.adju_path_1?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>
                  <HStack>
                    <Text>{KPNatural._PN_AD_DOCUMENTO}</Text>
                  </HStack>
                </FormLabel>
                <Input 
                  type="file" 
                  size={'sm'}
                  { ...register('adju_path_1', { 
                      required: KV._CAMPO_OBL, 
                      onChange: handleChangeFile,
                      validate: {
                        revisar: (v) => validarInputFile ( v, vParametros ) || KV._CTTO_MSJE_ADJUNTO_INPUT
                      },
                    }) } 
                />
                <FormErrorMessage fontSize="10">
                  {errors.adju_path_1 && errors.adju_path_1.message}
                </FormErrorMessage>
              </FormControl>

              </HStack>

              <Divider/>

              <Button 
                    colorScheme='green' 
                    variant='outline' 
                    type="submit"
                    isLoading={isSubmitting} 
                  >
                    {KPNatural._PN_AD_BOTON_SUBIR}
                  </Button>

            </VStack>

            { mostrarMensajeError && <Textarea isInvalid readOnly={true} bg={colorBGErrores} color={colorTextMensajes} value={`${mensajeActualizar}`} h={10}/> }

          </form>
        </VStack>
      </Container>

    )
}

export default AdjuntosForm;
