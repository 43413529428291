import { FC } from 'react';
import { IContratoConsultar, IUseFetch } from '../../interfaces/iEndpoints'
import {
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react'
import { formatFecha, formatMonto, truncarTexto } from '../../utils/utilidades';
import { FiEdit } from 'react-icons/fi';
import { Link as LinkRouter } from 'react-router-dom';
import { K, KContratos } from '../../utils/constantes';

interface Props {
  respuesta: IUseFetch,
}

const MisPendientesRspta: FC<Props> = ( {respuesta} ) => {

  const { cargando, data } = respuesta;
  const datos = data.data;
  const colorLink = useColorModeValue(K._COLOR_LINK_L, K._COLOR_LINK_D);

  return(
    <TableContainer  >
      <Table colorScheme='teal' size="sm">
          <Thead>
            <Tr bg={useColorModeValue("gray.100", "gray.600")} >
              <Th colSpan={2}></Th>
              <Th>{KContratos._MP_ID}</Th>
              <Th>{KContratos._MP_FECHA_PDTE}</Th>
              <Th>{KContratos._MP_CGESTION}</Th>
              <Th>{KContratos._MP_NOMBRE_CTTO}</Th>
              <Th>{KContratos._MP_FECHA_PPAL}</Th>
              <Th>{KContratos._MP_MONTO}</Th>
              <Th>{KContratos._MP_FECHA_INI}</Th>
              <Th>{KContratos._MP_FECHA_FIN}</Th>

            </Tr>
          </Thead>
          <Tbody>
          {
            cargando
            ?
            (<Tr key={1}>
              <Td colSpan={5}>
                <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
              </Td>
            </Tr>)
            :
            (
              ( (!datos) || (datos.length <= 0) )
              ?
              (<Tr key={1}>
                <Td colSpan={5}>
                  <h1>{K._NOHAYPDTES}</h1>
                </Td>
              </Tr>)
              :
              (
                datos.map((fila:IContratoConsultar, index:number) => (
                  (
                    <Tr key={index}>
                        <Td>{index+1}</Td>
                        <Td><LinkRouter to={`/form-contrato/${fila.ctto_ctto_id}`}><FiEdit fontSize="1.0rem" color={colorLink}/></LinkRouter></Td>
                        <Td>{fila.ctto_ctto_id}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_estado_actual, true)}</Td>
                        <Td>{fila.cges_cges_nombre}</Td>
                        <Td>{truncarTexto(fila.ctto_ctto_nombre, K._LARGO_TEXT_CONSULTAS)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_ppal, false)}</Td>
                        <Td isNumeric={true}>{formatMonto( fila.ctto_mone_id, parseFloat(fila.ctto_ctto_monto_total), 2)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_ini, false)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_fin, false)}</Td>
                    </Tr>
                  )
                )
              )
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>
    )
}

export default MisPendientesRspta;