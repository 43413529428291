import { useContext } from 'react';
import { TablasContext } from '../../context/TablasContext';
import { useForm } from 'react-hook-form';
import { K, KV } from '../../utils/constantes';
import { enviarCorreo, formatRUT, fParametros } from '../../utils/utilidades';
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackDivider,
  Textarea,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { AuthContext } from '../../context';
import { ICorreo } from '../../interfaces/iCorreo';

interface IFormCorreo {
  asunto: string;
  mensaje: string;
}

const Soporte = () => {

  const { usuario, ambiente } = useContext( AuthContext );
  const { tParametros } = useContext( TablasContext );

  const { register, handleSubmit, formState: { errors } } = useForm<IFormCorreo>();

  const colorBG     = useColorModeValue("gray.50", "gray.700");
  const colorBG2    = useColorModeValue("gray.200", "gray.900")
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
  const vParametros = fParametros(tParametros);

  const toast = useToast();

  const onSubmit = async ( data: IFormCorreo ) => {

    const usua_nombre = usuario ? usuario.pnat_nombre: '';
    const usua_correo = usuario ? usuario.pnat_correo : '';
    const usua_rut = usuario ? usuario.pnat_rut : '';

    const correo:ICorreo = {
      para_email: vParametros.correo_soporte_para,
      para_nombre: K._SOPORTE_NOMBRE,
      cc: usua_correo,
      cco: vParametros.correo_soporte_cco,
      asunto: data.asunto,
      cuerpo: `<br/>${data.mensaje}<br/><br/>${usua_nombre}<br/>${formatRUT(usua_rut)}<br/>${usua_correo}`,
    }

    const bResp = await enviarCorreo ( correo, ambiente );
   
    if (bResp) {
      toast({
        title: K._SOPORTE_MSJE_TITULO_EXITO,
        description: K._SOPORTE_MSJE_CORREO_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
    else {
      toast({
        title: K._SOPORTE_MSJE_TITULO_FRACASO,
        description: K._SOPORTE_MSJE_CORREO_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  return (
      <Container 
        py={{ base: '1', md: '1' }} 
        px={{ base: '1', md: '1' }} 
        maxW='3xl' 
        p="3" 
        bg={colorBG2}
      >
      <Box
        w='2xl' 
        p="3" 
        borderRadius={{ base: 'none', sm: 'md' }}
        bg={colorBG}
        >
          <form onSubmit={ handleSubmit(onSubmit) } >
        <VStack spacing="0" p='2'>
        <Container py={{ base: '4', md: '8' }}>
        <Stack spacing="5">
          <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
            <Heading size='sm' p={3} color={colorTitulo}>Solicitud de Soporte</Heading>
          </Stack>
          <Divider />
          <Stack spacing="5" divider={<StackDivider />}>
            <FormControl isInvalid={errors.asunto?true:false}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">Asunto</FormLabel>
                <Input 
                  maxW={{ md: '3xl' }} 
                  defaultValue={''} 
                  { ...register('asunto', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value: vParametros.correo_soporte_lmin, message: `${KV._LARGO_MIN} ${vParametros.correo_soporte_lmin}` },
                      maxLength: { value: vParametros.correo_soporte_lmax, message: `${KV._LARGO_MAX} ${vParametros.correo_soporte_lmax}` },
                    })
                  } 
                />
              </Stack>
              <FormErrorMessage fontSize="10">
                {errors.asunto && errors.asunto.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.mensaje?true:false}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <Box>
                  <FormLabel variant="inline">Mensaje</FormLabel>
                  <FormHelperText mt="0" color="muted">
                    Describa el requerimiento y aporte antecedentes
                  </FormHelperText>
                </Box>
                <Textarea 
                  maxW={{ md: '3xl' }} 
                  rows={5}  
                  { ...register('mensaje', 
                    { 
                      required: KV._CAMPO_OBL, 
                      minLength: { value: vParametros.correo_soporte_lmin, message: `${KV._LARGO_MIN} ${vParametros.correo_soporte_lmin}` },
                      maxLength: { value: vParametros.correo_soporte_lmax, message: `${KV._LARGO_MAX} ${vParametros.correo_soporte_lmax}` },
                    })
                  } 
                />
              </Stack>
              <FormErrorMessage fontSize="10">
                {errors.mensaje && errors.mensaje.message}
              </FormErrorMessage>
            </FormControl>

            <Flex direction="row-reverse">
              <Button type='submit'>Enviar solicitud</Button>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </VStack>
    </form>
      </Box>
    </Container>
      )
  }

  export default Soporte;