import { FC, useContext, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { TablasContext } from '../../context/TablasContext';
import { IContratoConsultar, IContratoSubir, IEndpoint, ITCTransiciones, IContratoBitacora } from '../../interfaces/iEndpoints';
import { K, KCasosTransicion, KContratos, KErroresDescargarContrato, KErroresSubirContrato, KEstado, KV } from '../../utils/constantes';
import { servContratoAutorizar, servContratoBitacoraConsultar, servContratoDescargar, servContratoSubir } from '../../utils/servicios';
import { enviarCorreo, fParametros, validarFileFirma, validarInputFileFirma, preparaNotificacion, preparaNotificacionRechazar, leerUsuario } from '../../utils/utilidades';
import { IconSubir4, IconDescargar4, IconDevolver } from '../../utils/Icons';
import { fetchSubirContrato } from '../../utils/fetchSubirContrato';
import { fetchTx } from '../../utils/fetchTx';
import { fetchDescargarContrato } from '../../utils/fetchDescargarContrato';
import { fetchQS } from '../../utils/fetchQS';import { 
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button, 
  Circle,
  Container, 
  Divider, 
  FormControl, 
  FormErrorMessage, 
  FormLabel,
  HStack, 
  Input,
  ListItem,
  Stack,
  Text, 
  UnorderedList,
  VStack, 
  Textarea,
  useColorModeValue, 
  useToast,
  Heading,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { IPNaturalContext } from '../../interfaces/iPNaturalContext';


interface Props {
    id: number;
    datos: IContratoConsultar;
    ambiente: string | undefined;
    usuario: IPNaturalContext | undefined;
    setPrimeraVez: React.Dispatch<React.SetStateAction<boolean>>;
    // actualizar: number;
    // setActualizar: React.Dispatch<React.SetStateAction<number>>;
}

interface DSubir {
  ctto_id: number;
  caso: string;
  esta_id_actual: string;
  esta_id_nuevo: string;
  archivo: string | Blob;
  usua_id: string | null;
  mensaje: string;
  rut:  string;
}

// const ContratoFirmarForm: FC<Props> = ({ id, ambiente, usua_id, actualizar, setActualizar }) => {
const ContratoFirmarForm: FC<Props> = ({ id, datos, ambiente, usuario, setPrimeraVez }) => {

  const { tParametros, tTCTransicionesPerfiles } = useContext( TablasContext );
  const { register, handleSubmit, formState: { errors, isSubmitting, isValid } } = useForm<IContratoSubir>();
  const [ errorDetectado, setErrorDetectado ] = useState('');
  const [ archDescagado, setArchDescagado ] = useState('');
  const [ cargando, setCargando ] = useState(true);
  const [ bLeerBitacora, setBLeerBitacora ] = useState(true);
  const [ bitacora, setBitacora ] = useState([]);
  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ usuarioF1, setUsuarioF1 ] = useState({});
  const [ usuarioF2, setUsuarioF2 ] = useState({});
  const [ usuarioCG, setUsuarioCG ] = useState({});

  const toast       = useToast();
  const colorBG     = useColorModeValue("white", "gray.700");

  const colorLabel  = useColorModeValue("blue.500", "whiteAlpha.800");
  const bgInput     = useColorModeValue("white", "gray.600");

  const usua_id     = usuario?.pnat_rut;
  const arch_prefijo = `${datos.ctto_pnat_rut}_${id}_${datos.ctto_ctto_nro_interno}`;
  const arch_sufijo = (datos.ctto_esta_id_actual === KEstado._FIRMA_1_OK) ? ('_f1') : ((datos.ctto_esta_id_actual === KEstado._FIRMA_PROVEEDOR_OK) ? ('_f1_fp') : (''));

  let tTCTranscionesCtto:ITCTransiciones[] =  [];
  if (datos) {
    tTCTranscionesCtto =  tTCTransicionesPerfiles.filter (transicion => ( (transicion.trsn_tcon_id === datos.ctto_tcon_id) && (transicion.trsn_esta_id_ini === datos.ctto_esta_id_actual) && ( transicion.trpf_perf_id === K._PERFIL_PNATURAL ) ) );
  }    

  const vParametros = fParametros(tParametros);

  const leerBitacora = async () => {

    await fetchQS (servContratoBitacoraConsultar, id, ambiente)
      .then ( resp => {
        if (resp.code !== 0) {
          setErrorDetectado(KContratos._SC_MSJE_ERROR_BITACORA);
        }
        else if (resp.data.length > 0) {
          const datosR:any = resp.data;
          setBitacora(datosR);
        } 
        setCargando(false);
      })
      .catch ( (error) => {
          const { message } = JSON.parse(error.message);
          setErrorDetectado(message);
          setCargando(false);
      })    
  }

  const handleChangeFile = (e:React.FormEvent<HTMLInputElement>) => {
    const [validaOK, validaMsje] = validarFileFirma ( (e.currentTarget.files ? e.currentTarget.files[0] : null), vParametros, arch_prefijo );
    if (!validaOK) {
      toast({
        title: KContratos._SC_MSJE_SUBIR_AVISO,
        description: validaMsje,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  const onClickDescargar = (e: React.SyntheticEvent):void => {

    var bita_id = '';
    var bita_pdf = 'error_pdf';
    for (let index = 0; index < bitacora.length; index++) {
      const element:IContratoBitacora = bitacora[index];
      switch (datos.ctto_esta_id_actual) {
        case KEstado._ENVIADO_A_FIRMA_1:
          if (element.bita_esta_id === KEstado._ENVIADO_A_FIRMA_1) {
            bita_id = element.bita_bita_id;
            bita_pdf = (element.bita_bita_pdf) ? element.bita_bita_pdf : `${arch_prefijo}${arch_sufijo}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`; 
          }
          break;
        case KEstado._FIRMA_1_OK:
          if (element.bita_esta_id === KEstado._FIRMA_1_OK) {
            bita_id = element.bita_bita_id;
            bita_pdf = (element.bita_bita_pdf) ? element.bita_bita_pdf : `${arch_prefijo}${arch_sufijo}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`; 
          }
          break;
        case KEstado._FIRMA_PROVEEDOR_OK:
          if (element.bita_esta_id === KEstado._FIRMA_PROVEEDOR_OK) {
            bita_id = element.bita_bita_id;
            bita_pdf = (element.bita_bita_pdf) ? element.bita_bita_pdf : `${arch_prefijo}${arch_sufijo}.${KV._CTTO_ADJUNTO_EXTE_CTTO}`; 
          }
          break;
      }
    }

    if (bita_id !== '') {
      fetchDescargarContrato (servContratoDescargar, id, bita_id, bita_pdf, ambiente)
      .then ( resp => { 
        if (resp.code !== 0) { 

          const errorDescargar = KErroresDescargarContrato.filter (elemento => (elemento.codigo ===  resp.code) );
          const errorMensaje = (errorDescargar.length > 0) ? errorDescargar[0].mensaje : `[${resp.code} // ${resp.data}]`;

          toast({
            title: KContratos._SC_MSJE_TITULO_FRACASO,
            description: errorMensaje,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
        else {
          setArchDescagado(bita_pdf);
          toast({
            title: KContratos._SC_MSJE_TITULO_EXITO,
            description: KContratos._SC_MSJE_DESCARGAR_EXITO,
            status: 'success',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
      })
      .catch ( (error) => {
        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO}`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      })    
    }
    else {
      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [c]`,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  const onClickRechazar =  ( ) => { onOpen(); }

  const onRechazar =  async ( data:IContratoSubir ) => {

    onOpen(); 

    const esta_id_actual = datos.ctto_esta_id_actual;

    servContratoAutorizar.params = {
      ...servContratoAutorizar.params,
      esta_id_actual: esta_id_actual,
      esta_id_nuevo: KEstado._RECHAZADO,
			usua_id: usua_id || '?rechazarCtto',
      caso: 
      (
				(esta_id_actual === KEstado._ENVIADO_A_FIRMA_1) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMA1_RECHAZO
				: 
				(esta_id_actual === KEstado._FIRMA_1_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMAP_RECHAZO
				: 
				(esta_id_actual === KEstado._FIRMA_PROVEEDOR_OK) 
				? 
          KCasosTransicion._CASO_TRANS_FIRMA2_RECHAZO
				:
					''
      ),
      mensaje: data.mensaje,
    }

    if (servContratoAutorizar.params.mensaje.length <= 0) {

      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: KContratos._SC_MSJE_RECHAZAR_SIN_MENSAJE,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
      return(false);

    }
    else {

      const resp:IEndpoint = await fetchTx (servContratoAutorizar, id, ambiente);

      if (resp.code === 0) {
        setPrimeraVez(true);

        const correo = preparaNotificacionRechazar (datos, vParametros, data.mensaje, usuarioCG);
        await enviarCorreo ( correo, ambiente );

        toast({
          title: KContratos._SC_MSJE_TITULO_EXITO,
          description: KContratos._SC_MSJE_RECHAZAR_EXITO,
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
        return(true);
      }
      else {

        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: KContratos._SC_MSJE_RECHAZAR_FRACASO,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
        return(false);
      }
    }
  }

  const onFirmar = async ( data:IContratoSubir ) => {

    var caso = '';
    var rut = '';
    var esta_id_nuevo = '';
    
    switch (datos.ctto_esta_id_actual) {
      // case KEstado._ENVIADO_A_FIRMA_1:
      //   caso = KCasosTransicion._CASO_TRANS_FIRMA1_OK;
      //   esta_id_nuevo = KEstado._FIRMA_1_OK;
      //   const usua1:any = usuarioF1;
      //   rut = usua1.usua_usua_rut;
      //   break;
      case KEstado._FIRMA_1_OK:
        caso = KCasosTransicion._CASO_TRANS_FIRMAP_OK;
        esta_id_nuevo = KEstado._FIRMA_PROVEEDOR_OK;
        const usua1:any = usuarioF1;
        rut = `${usua1.usua_usua_rut},${datos.ctto_pnat_rut}`;
        break;
      // case KEstado._FIRMA_PROVEEDOR_OK:
      //   caso = KCasosTransicion._CASO_TRANS_FIRMA2_OK;
      //   esta_id_nuevo = KEstado._FIRMA_2_OK;
      //   const usua2:any = usuarioF2;
      //   rut = usua2.usua_usua_rut;
      //   break;
    }
    
    var params:DSubir = {
      ctto_id: id,
      caso: caso,
      esta_id_actual: datos.ctto_esta_id_actual,
      esta_id_nuevo: esta_id_nuevo,
      archivo: data.archivo ? data.archivo[0] : '',
      usua_id: usua_id || '?subirCtto',
      mensaje: data.mensaje,
      rut: rut,
    }

    if (data.archivo) {

      const resp:IEndpoint = await fetchSubirContrato ( params, servContratoSubir, ambiente );

      if (resp.code === 0) {

        const correo = preparaNotificacion (datos, vParametros, usuarioF1, usuarioF2, usuarioCG);
        await enviarCorreo ( correo, ambiente );

        setPrimeraVez(true);

        toast({
          title: KContratos._SC_MSJE_TITULO_EXITO,
          description: `${KContratos._SC_MSJE_SUBIR_EXITO}`,
          status: 'success',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
      else {

        const errorSubir = KErroresSubirContrato.filter (elemento => (elemento.codigo ===  resp.code) );
        const errorMensaje = (errorSubir.length > 0) ? errorSubir[0].mensaje : `[${resp.code} // ${resp.data}]`;
        
        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: `${errorMensaje} [a]`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      }
    }
    else {
      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: `${KContratos._SC_MSJE_SUBIR_FRACASO} [b]`,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
    // setActualizar(actualizar+1);
  }

  const leerUsuarios = async () => {
    const usuarioF1:any = await leerUsuario (datos.tcon_usua_id_firma1, ambiente);
    const usuarioF2:any = await leerUsuario (datos.tcon_usua_id_firma2, ambiente);
    const usuarioCG:any = await leerUsuario (datos.cges_usua_id_responsable, ambiente);
    setUsuarioF1 ( usuarioF1 );
    setUsuarioF2 ( usuarioF2 );
    setUsuarioCG ( usuarioCG );
    // console.log('___usuarios___', usuarioF1, usuarioF2, usuarioCG)  
  }

  if (bLeerBitacora) {
    leerBitacora();
    leerUsuarios();
    setBLeerBitacora (false);
  }

  if (errorDetectado)
    return (<Text>{errorDetectado}</Text>)
  else if ( (tTCTranscionesCtto.length <= 0) || (datos.ctto_esta_id_actual === KEstado._BORRADOR) || (datos.ctto_esta_id_actual === KEstado._RECHAZADO) )
    return (<Heading size='xs'>{KContratos._SC_MSJE_NO_REQ_FIRMA}</Heading>);
  else if (cargando) {
    return (<Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />)
  }
  else
    return(
        <Container 
            py={{ base: '1', md: '2' }} 
            px={{ base: '1', md: '4' }} 
            bg={colorBG}
        >
          <VStack p="1" align={'left'}>

            {/* --------------- PASO 1: DESCARGAR --------------------------------*/}
            <Stack spacing="4" direction="row" >
              <Stack spacing="0" align="center">
                <Circle
                  size="8"
                  bg={'teal.300'}
                  borderWidth={'2px'}
                  borderColor={'green'}
                >
                  <Text color={'white'} fontWeight={'bold'}>1</Text>
                </Circle>
                <Divider
                  orientation="vertical"
                  borderWidth="1px"
                  borderColor={'blue'}
                />
              </Stack>

              <Stack>
                <Text color="emphasized" fontSize={'md'}>{KContratos._SC_PASO1_DESCRIPCION}</Text>
                <Stack p={'4'}>
                  <Button 
                    colorScheme='blue' 
                    variant='outline' 
                    type="button"
                    w={'xs'}
                    fontSize={'sm'}
                    leftIcon={<IconDescargar4 />}
                    onClick={onClickDescargar}
                    id={`${datos.ctto_ctto_id}|${datos.ctto_ctto_id}`} 
                  >
                    {KContratos._SC_BOTON_DESCARGAR}
                  </Button>
                  <Text color="teal" fontSize={'md'}>{archDescagado}</Text>
                </Stack>
              </Stack>

            </Stack>
            
            {/* --------------- PASO 2: FIRMAR --------------------------------*/}
            <Stack spacing="4" direction="row">
              <Stack spacing="0" align="center">
                <Circle
                  size="8"
                  bg={'teal.300'}
                  borderWidth={'2px'}
                  borderColor={'green'}
                >
                  <Text color={'white'} fontWeight={'bold'}>2</Text>
                </Circle>

                <Divider
                  orientation="vertical"
                  borderWidth="1px"
                  borderColor={'blue'}
                />
              </Stack>

              <Stack pb={'8'}>
                <Text color="emphasized" fontSize={'md'}>{KContratos._SC_PASO2_DESCRIPCION}</Text>
                <Accordion allowToggle={true}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          {KContratos._SC_PASO2_AYUDA_TITULO}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text>{KContratos._SC_PASO2_AYUDA_PARRAFO1}</Text>
                      <UnorderedList>
                        <ListItem>{KContratos._SC_PASO2_AYUDA_PARRAFO1_1}</ListItem>
                        <ListItem>{KContratos._SC_PASO2_AYUDA_PARRAFO1_2}</ListItem>
                        <ListItem>{KContratos._SC_PASO2_AYUDA_PARRAFO1_3}</ListItem>
                      </UnorderedList>
                      <br/>
                      <p>{KContratos._SC_PASO2_AYUDA_PARRAFO2}</p>
                      <p>{KContratos._SC_PASO2_AYUDA_PARRAFO3}</p>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Stack>
            </Stack>

            {/* --------------- PASO 3: SUBIR --------------------------------*/}
            <form onSubmit={ handleSubmit(onFirmar) }  >
              <Stack spacing="4" direction="row" >
                <Stack spacing="0" align="center">
                  <Circle
                    size="8"
                    bg={'teal.300'}
                    borderWidth={'2px'}
                    borderColor={'green'}
                    >
                    <Text color={'white'} fontWeight={'bold'}>3</Text>
                  </Circle>

                  <Divider
                    orientation="vertical"
                    borderWidth="1px"
                    borderColor={'blue'}
                  />
                </Stack>

                <Stack >
                  <Text color="emphasized" fontSize={'md'}>{KContratos._SC_PASO3_DESCRIPCION}</Text>
                  <VStack>
                    <FormControl isInvalid={errors.archivo?true:false}>
                      <Input 
                        type="file" 
                        size={'sm'}
                        { ...register('archivo', { 
                            onChange: handleChangeFile,
                            validate: {
                              revisar: (v) => validarInputFileFirma ( v, vParametros, arch_prefijo ) || KContratos._SC_MSJE_NO_ARCH_PERMITIDO
                            },
                        }) } 
                      />
                      <FormErrorMessage fontSize="10">
                        {errors.archivo && errors.archivo.message}
                      </FormErrorMessage>
                    </FormControl>

                    { /* mensaje ----------------------------------------------------------- */ }
                    <FormControl isInvalid={errors.mensaje?true:false}>
                      <FormLabel fontSize="xs" color={colorLabel}>
                        <HStack>
                          <Text>{KContratos._SC_MSJE_RECHAZAR}</Text>
                        </HStack>
                      </FormLabel>
                      <Textarea
                        bg={bgInput} 
                        defaultValue={''} 
                        { ...register('mensaje', 
                          { 
                            maxLength: { value: vParametros.ctto_mensajes_lmax, message: `${KV._LARGO_MAX} ${vParametros.ctto_mensajes_lmax}` },
                          })
                        }
                      />
                      <FormErrorMessage fontSize="10">
                        {errors.mensaje && errors.mensaje.message}
                      </FormErrorMessage>
                    </FormControl>

                    <HStack>
                      { /* boton subir ------------------------------------------------------ */ }
                      <Button 
                        colorScheme='blue' 
                        variant='outline' 
                        isLoading={isSubmitting} 
                        fontSize='sm'
                        leftIcon={<IconSubir4 />}
                        type='submit'
                      >
                        {KContratos._SC_BOTON_SUBIR}
                      </Button>

                      { /* boton rechazo ------------------------------------------------------ */ }
                      <Button 
                        colorScheme='blue' 
                        variant='outline' 
                        isLoading={isSubmitting} 
                        fontSize={'sm'}
                        onClick={ handleSubmit(onClickRechazar) }
                        leftIcon={<IconDevolver />}
                      >
                        {KContratos._SC_BOTON_RECHAZAR}
                      </Button>
                    </HStack>
                  </VStack>
                </Stack>
              </Stack>

              <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
              >
                <AlertDialogOverlay />
                <AlertDialogContent>
                  <AlertDialogHeader>
                    {KContratos._SC_CONFIRMAR_RECHAZAR_TITULO}
                  </AlertDialogHeader>
                  <AlertDialogCloseButton />
                  <AlertDialogBody>
                    {
                      !isValid 
                      ? 
                        KContratos._F_MSJE_ERROR_FORM
                      :
                        KContratos._SC_CONFIRMAR_RECHAZAR_CUERPO
                      }
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      {KContratos._F_BOTON_CONFIRMAR_CANCELAR}
                    </Button>
                    {
                      !isValid 
                      ?
                      null
                      :

                    <Button 
                      colorScheme='red' 
                      ml={3} 
                      onClick={ handleSubmit(onRechazar) }
                    >
                      {KContratos._F_BOTON_CONFIRMAR_ACEPTAR}
                    </Button>
                    }
                    </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>  
            </form>

          </VStack>
        </Container>
    )
}

export default ContratoFirmarForm;
