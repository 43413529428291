import { K } from "./constantes";

export const servResumenEjecutivo = {
    endPoint: '/cuadro-gestion/cgestion/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
    }
}

export const servUsuario = {
    endPoint: '/usuario/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: true,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        usua_id: '',
        usua_vigente: 'S'
    }
}

export const servContratosConsultar = {
    endPoint: '/contrato/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        pnat_rut: '',
        ctto_id: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        random: 0,
    }
}

export const servContratoModificar = {
    endPoint: '/contrato/modificar',
    metodo: 'PUT',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
    }
}

export const servContratoCrear = {
    endPoint: '/contrato/crear',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
    }
}

export const servContratoEliminar = {
    endPoint: '/contrato/eliminar',
    metodo: 'DELETE',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
    }
}

export const servContratoGenerarPDF = {
    endPoint: '/contrato/generar',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
        nombre_archivo: '',
    }
}

export const servContratoAutorizar = {
    endPoint: '/contrato/autorizar',
    metodo: 'PUT',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        esta_id_actual: '',
        esta_id_nuevo: '',
        usua_id: '',
        caso: '',
        mensaje: ''
    }
}

export const servContratoAdjuntoConsultar = {
    endPoint: '/contrato/adjunto/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        pnat_rut: '',
        ctto_id: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        random: 0,
    }
}

export const servContratoBitacoraConsultar = {
    endPoint: '/contrato/bitacora/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        pnat_rut: '',
        ctto_id: '',
        ctto_nro_interno: '',
        ctto_nombre: '',
        cges_id_list: '',
        esta_id_list: '',
        ctto_fecha_ini: '',
        ctto_fecha_fin: '',
        random: 0,
    }
}

export const servContratoAdjuntoSubir = {
    endPoint: '/contrato/adjunto/subir',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'formdata',
    contentType: 'multipart/form-data',
    params: {
    }
}

export const servContratoAdjuntoDescargar = {
    endPoint: '/contrato/adjunto/descargar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'qstring',
    contentType: 'multipart/form-data',
    params: {
    }
}

export const servPNaturalesConsultar = {
    endPoint: '/persona-natural/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
    }
}

export const servPNaturalesModificar = {
    endPoint: '/persona-natural/modificar',
    metodo: 'PUT',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
        pnat_nacional: '',
        pnat_nombre1: '',
        pnat_nombre2: '',
        pnat_apellido1: '',
        pnat_apellido2: '',
        pnat_alias: '',
        perf_id: '',
        pnat_correo: '',
        pnat_telefono: '',
        pnat_profesion: '',
        pnat_genero: '',
        pnat_fecha_nac: '',
        naci_id: '',
        eciv_id: '',
        pnat_direccion: '',
        pnat_direccion_compl: '',
        comu_id: '',
        regi_id: '',
        pais_id: '',
        pnat_tx_cuenta_banco: '',
        pnat_tx_cuenta_nro: '',
        pnat_tx_cuenta_tipo: '',
        pnat_cod_postal: '',
        pnat_decljurada_id:  0,
        pnat_decljurada_fecha: '',
        cges_id_default: '',
        pnat_pers_id_insc: '',
        pnat_vigente: '',
        usua_id:  ''
    }
}


export const servPNaturalesCrear = {
    endPoint: '/persona-natural/crear',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
        pnat_rut: '',
        pnat_nacional: '',
        pnat_nombre1: '',
        pnat_nombre2: '',
        pnat_apellido1: '',
        pnat_apellido2: '',
        pnat_alias: '',
        perf_id: '',
        pnat_correo: '',
        pnat_telefono: '',
        pnat_profesion: '',
        pnat_genero: '',
        pnat_fecha_nac: '',
        naci_id: '',
        eciv_id: '',
        pnat_direccion: '',
        pnat_direccion_compl: '',
        comu_id: '',
        regi_id: '',
        pais_id: '',
        pnat_tx_cuenta_banco: '',
        pnat_tx_cuenta_nro: '',
        pnat_tx_cuenta_tipo: '',
        pnat_cod_postal: '',
        pnat_decljurada_id:  0,
        pnat_decljurada_fecha: '',
        cges_id_default: '',
        pnat_pers_id_insc:  0,
        pnat_vigente: '',
        usua_id:  ''
    }
}

export const servPJuridicas = {
    endPoint: '/persona-juridica',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        pjur_rut: '101884384',
        pjur_vigente: 'S'
    }
}

export const servPJuridicasRNaturales = {
    endPoint: '/persona-juridica/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        pjur_rut: '101884384',
        pjur_vigente: 'S'
    }
}

export const servPermisosAplicacion = {
    endPoint: '/aplicacion/permiso/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        apps_id: 'panel_control',
        perf_id: 'cgestion_grte_vclu',
    }
}

export const servMenuUsuario = {
    endPoint: '/menu/usuario/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: true,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        usua_id: '',
        menu_id: '',
        menu_id_padre: '',
    }
}

export const servConfiguracion = {
    endPoint: '/configuracion/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        caso: '',
        tcon_id: '',
        esta_id: '',
        perf_id: '',
        esta_id_ini: '',
        cges_id: '',
        corr_ano: '',
        ctto_id: '',
        camp_id: '',
        tabl_id: '',
        tabp_id: '',
        tabp_elemento: '',
        tabh_id: '',
        tabh_elemento: '',
    }
}

export const servAplicacionPermisosConsultar = {
    endPoint: '/aplicacion/permiso/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
    }
}


export const servMumeroALetras = {
    endPoint: '/util/numero-a-letras',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
        numero: '0',
        genero: K._MASCULINO,
    }
}

export const servCorreoEnviar = {
    endPoint: '/correo/enviar',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: false,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
        para_email: '',
        para_nombre: '',
        cc: '',
        cco: '',
        asunto: '',
        cuerpo: '',
    }
}


export const servClaveRecuperar = {
    endPoint: '/clave/recuperar',
    metodo: 'POST',
    esApi: true,
    usaToken: false,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: false,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
        caso: '',
        rut: '',
    }
}

export const servClaveModificar = {
    endPoint: '/clave/modificar',
    metodo: 'POST',
    esApi: true,
    usaToken: false,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: false,
    tipoParams: 'body',
    contentType: 'application/json',
    params: {
        caso: '',
        rut: '',
        pin: '',
        clave: ''
    }
}



export const servContratoSubir = {
    endPoint: '/contrato/firmado/subir',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'formdata',
    contentType: 'multipart/form-data',
    params: {
    }
}

export const servContratoDescargar = {
    endPoint: '/contrato/firmado/descargar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'qstring',
    contentType: 'multipart/form-data',
    params: {
    }
}

export const servProveedorAdjuntoConsultar = {
    endPoint: '/proveedor/adjunto/consultar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: false,
    usaUsuario: false,
    tipoParams: 'qstring',
    contentType: 'application/json',
    params: {
    }
}

export const servProveedorAdjuntoSubir = {
    endPoint: '/proveedor/adjunto/subir',
    metodo: 'POST',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'formdata',
    contentType: 'multipart/form-data',
    params: {
    }
}

export const servProveedorAdjuntoDescargar = {
    endPoint: '/proveedor/adjunto/descargar',
    metodo: 'GET',
    esApi: true,
    usaToken: true,
    usaHeaders: true,
    usaBody: true,
    usaUsuario: true,
    tipoParams: 'qstring',
    contentType: 'multipart/form-data',
    params: {
    }
}
