import { Route, Routes, Navigate } from 'react-router-dom';
import { Portal } from '../componentes/portal/Portal';
import MisPendientes from '../componentes/misPendientes/MisPendientes';
import ContratoCons from '../componentes/contratoCons/ContratoCons';
import Soporte from '../componentes/soporte/Soporte';
import PNaturalEditar from '../componentes/pnaturalMant/PNaturalEditar';
import ContratoMant from '../componentes/contratoMant/ContratoMant';
import Adjuntos from '../componentes/adjuntos/Adjuntos';

const RoutesPortal = () => {
    return(
        <div>
            <Routes>
                <Route exact path="/inicio" element={ <Portal/> } />
                <Route exact path="/mis-pendientes" element={ <MisPendientes/> } />
                <Route exact path="/adjuntos" element={ <Adjuntos/> } />
                <Route exact path="/soporte" element={ <Soporte/> } />
                <Route strict path="/datos-personales"  element={ <PNaturalEditar/> } />
                <Route path="/contratos" element={ <ContratoCons/> } />
                <Route path="/form-contrato/:id" element={ <ContratoMant/> } />
                <Route path="*" element={<Navigate to="/mis-pendientes" replace />} />
            </Routes>
        </div>
    )
}

export default RoutesPortal;