import { FC, useContext, useState } from 'react';
import { IContratoBitacora, IContratoConsultar, IUseFetch } from '../../interfaces/iEndpoints'
import {
    Button,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react'
import { formatFecha, formatMonto, truncarTexto } from '../../utils/utilidades';
import { K, KContratos, KEstado } from '../../utils/constantes';
import { fetchDescargarContrato } from '../../utils/fetchDescargarContrato';
import { servContratoBitacoraConsultar, servContratoDescargar } from '../../utils/servicios';
import { AuthContext } from '../../context';
import { fetchQS } from '../../utils/fetchQS';

interface Props {
  respuesta: IUseFetch,
}

const ContratoConsRspta: FC<Props> = ( {respuesta} ) => {

  const { ambiente } = useContext( AuthContext );
  const { cargando, data } = respuesta;

  const [ errorDetectado, setErrorDetectado ] = useState('');
  // const [ bitacora, setBitacora ] = useState([]);

  const datos:IContratoConsultar[] = data.data;
  const colorNroLinea = useColorModeValue(K._COLOR_NROLINEA_L, K._COLOR_NROLINEA_D);

  const toast       = useToast();

  const leerBitacora = async (id: string) => {
    var datosR:any = [];

    await fetchQS (servContratoBitacoraConsultar, id, ambiente)
      .then ( resp => {
        if (resp.code !== 0) {
          setErrorDetectado(KContratos._SC_MSJE_ERROR_BITACORA);
        }
        else if (resp.data.length > 0) {
          datosR = resp.data;
          // setBitacora(datosR);
        } 
      })
      .catch ( (error) => {
          const { message } = JSON.parse(error.message);
          setErrorDetectado(message);
      })    

    return (datosR)
  }

  const handleOnClick = async (e: React.SyntheticEvent) => {

    const ctto_id = e.currentTarget.id;

    const bitacora:IContratoBitacora[] = await leerBitacora(ctto_id);
  
    if (errorDetectado) {
      toast({
        title: KContratos._SC_MSJE_TITULO_FRACASO,
        description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [B]`,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }

    else {

      var bita_id = '';
      var bita_pdf = 'error_pdf';

      for (let index = 0; index < bitacora.length; index++) {
        const element:IContratoBitacora = bitacora[index];
        if (element.bita_esta_id === KEstado._FIRMA_2_OK) {
          bita_id = element.bita_bita_id;
          bita_pdf = element.bita_bita_pdf; 
        }
      }
  
      fetchDescargarContrato (servContratoDescargar, ctto_id, bita_id, bita_pdf, ambiente)
      .then ( resp => {
        if (resp.code !== 0) {
          toast({
            title: KContratos._SC_MSJE_TITULO_FRACASO,
            description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [C]`,
            status: 'error',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
        else {
          toast({
            title: KContratos._SC_MSJE_TITULO_EXITO,
            description: KContratos._SC_MSJE_DESCARGAR_EXITO,
            status: 'success',
            duration: K._DURACION_TOAST_DEFAULT,
            isClosable: true,
          })
        }
      })

      .catch ( (error) => {
        toast({
          title: KContratos._SC_MSJE_TITULO_FRACASO,
          description: `${KContratos._SC_MSJE_DESCARGAR_FRACASO} [b]`,
          status: 'error',
          duration: K._DURACION_TOAST_DEFAULT,
          isClosable: true,
        })
      })    
    }

  }

  return(
    <TableContainer  >
      <Table colorScheme='teal' size="sm">
          <Thead>
            <Tr bg={useColorModeValue("gray.100", "gray.600")} >
              <Th></Th>
              <Th>{KContratos._TH_ID}</Th>
              <Th>{KContratos._TH_CGESTION}</Th>
              <Th>{KContratos._TH_CONTRATO}</Th>
              <Th>{KContratos._TH_FECHA_PPAL}</Th>
              <Th>{KContratos._TH_MONTO}</Th>
              <Th>{KContratos._TH_FECHA_INI}</Th>
              <Th>{KContratos._TH_FECHA_FIN}</Th>
              <Th>descargar</Th>
            </Tr>
          </Thead>
          <Tbody>
          {
            cargando
            ?
            (<Tr key={1}>
              <Td colSpan={5}>
                <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
              </Td>
            </Tr>)
            :
            (
              ( (!datos) || (datos.length <= 0) )
              ?
              (<Tr key={1}>
                <Td colSpan={5}>
                  <h1>{K._NOHAYDATOS_F}</h1>
                </Td>
              </Tr>)
              :
              (
                datos.map((fila:IContratoConsultar, index:number) => (
                  (
                    <Tr key={index}>
                        <Td color={colorNroLinea}>{index+1}</Td>
                        <Td>{fila.ctto_ctto_id}</Td>
                        <Td>{fila.cges_cges_nombre}</Td>
                        <Td>{truncarTexto(fila.ctto_ctto_nombre, K._LARGO_TEXT_CONSULTAS)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_ppal, false)}</Td>
                        <Td isNumeric={true}>{formatMonto( fila.ctto_mone_id, parseFloat(fila.ctto_ctto_monto_total), 2)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_ini, false)}</Td>
                        <Td>{formatFecha(fila.ctto_ctto_fecha_fin, false)}</Td>
                        <Td>
                          <Button 
                            variant='outline' 
                            onClick={handleOnClick} 
                            size={'xs'} 
                            id={fila.ctto_ctto_id} 
                            value={fila.ctto_ctto_id} 
                            title={fila.ctto_ctto_id}
                          >
                            {KContratos._BI_BOTON_DESCARGA}
                          </Button>
                        </Td>
                    </Tr>
                  )
                )
              )
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>
    )
}

export default ContratoConsRspta;