import { useState, useContext } from 'react';
import { AuthContext } from '../../context';
import { useForm } from 'react-hook-form'
import { Link as LinkRouter  } from 'react-router-dom'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Image,
    Stack,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
    Text,
  } from '@chakra-ui/react'
import * as React from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { Portal } from '../portal/Portal';
import { KConexion, KV, _APPS_BUILD, _APPS_NOMBRE, _APPS_VERSION } from '../../utils/constantes';

type FormData = {
  usua_id   : string,
  usua_clave     : string,
};
   
export const Conexion = () => {

    const { login } = useContext( AuthContext );

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<FormData>();
    const [ showError, setShowError ] = useState(false);
    
    const { isOpen, onToggle } = useDisclosure()
    const inputRef = React.useRef<HTMLInputElement>(null)

    const onClickReveal = () => {
      onToggle()
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true })
      }
    }

    const onSubmit = async( { usua_id, usua_clave }: FormData ) => {

        setShowError(false);

        const isValidLogin = await login( usua_id, usua_clave );

        if ( !isValidLogin ) {
            setShowError(true);
            setTimeout(() => setShowError(false), 8000);
            return;
        }

        return <Portal/>

    }

  const logo = useColorModeValue("/ccv_h_light.png", "/ccv_h_dark.png");

    return (
      <>
      <Container 
        maxW="container.2xl" 
        p={5}
        bg={useColorModeValue('teal.50', 'gray.700')}
      >
        <Flex
          h={{ base: 'auto', md: '110vh' }}
          py={[0, 5, 5, 5]}
          direction={{ base: 'column-reverse', md: 'row' }}
          bg={useColorModeValue('teal.50', 'gray.700')}
        >
          <Container 
            maxW="lg" 
            py={{ base: '4', md: '8' }} 
            px={{ base: '0', sm: '8' }} 
            bg={useColorModeValue('teal.50', 'gray.700')}
            borderRadius={{ base: 'none', sm: 'md' }}
            >
            <Stack spacing="4">
              <Stack spacing="3" align={"center"}>
                <Image src={logo} alt="CCV" w={40} textAlign="center"/>
                <Stack spacing={{ base: '1', md: '1' }} textAlign="center">
                  <Heading size={useBreakpointValue({ base: 'md', md: 'md' })}>
                    { _APPS_NOMBRE }
                  </Heading>
                  <Text size="xs" title={_APPS_BUILD}>{ _APPS_VERSION }</Text>
                </Stack>
              </Stack>
              <Box
                py={{ base: '0', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                borderRadius={{ base: 'none', sm: 'xl' }}
              >
                <form onSubmit={ handleSubmit(onSubmit) } noValidate>
                  <Stack spacing="4">
                    <Stack spacing="4">
                    <FormControl isInvalid={errors.usua_id?true:false}>
                        <FormLabel htmlFor="usua_id">{ KConexion._CONX_LABEL_USUARIO }</FormLabel>
                        <Input 
                          id="usua_id" 
                          type="text" 
                          {...register('usua_id', {
                            required: KConexion._CONX_MSJE_INGR_USUARIO,
                            minLength: { value: KConexion._CONX_USUARIO_LMIN, message: `${KV._LARGO_MIN} ${KConexion._CONX_USUARIO_LMIN}` },
                            maxLength: { value: KConexion._CONX_USUARIO_LMAX, message: `${KV._LARGO_MAX} ${KConexion._CONX_USUARIO_LMAX}` },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.usua_id && errors.usua_id.message}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={errors.usua_clave?true:false}>
                        <FormLabel htmlFor="usua_clave">{KConexion._CONX_LABEL_CLAVE}</FormLabel>
                        <InputGroup>
                          <InputRightElement>
                            <IconButton
                              variant="link"
                              aria-label={isOpen ? KConexion._CONX_CLAVE_OCULTAR : KConexion._CONX_CLAVE_MOSTRAR }
                              icon={isOpen ? <HiEyeOff /> : <HiEye />}
                              onClick={onClickReveal}
                            />
                          </InputRightElement>
                          <Input
                            id="usua_clave"
                            type={isOpen ? 'text' : 'password'}
                            {...register('usua_clave', {
                              required:  KConexion._CONX_MSJE_INGR_CLAVE,
                              minLength: { value: KConexion._CONX_CLAVE_LMIN, message: `${KV._LARGO_MIN} ${KConexion._CONX_CLAVE_LMIN}` },
                              maxLength: { value: KConexion._CONX_CLAVE_LMAX, message: `${KV._LARGO_MAX} ${KConexion._CONX_CLAVE_LMAX}` },
                            })}
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {errors.usua_clave && errors.usua_clave.message}
                        </FormErrorMessage>

                      </FormControl>
                    </Stack>

                    <HStack justify="space-between">
                      <LinkRouter to="/recuperar-clave">
                          <Button variant="link" colorScheme="blue" size="sm" justifyContent="start" id="b_datos">{KConexion._CONX_LABEL_OLVIDO_CLAVE}</Button>
                      </LinkRouter>
                    </HStack>

                    <Stack spacing="6">
                      <Button type="submit" isLoading={isSubmitting}>{KConexion._CONX_LABEL_INGRESAR}</Button>
                    </Stack>

                    { 
                      showError && 
                      (
                      <Alert status='error'>
                        <AlertIcon />
                        <AlertDescription>{KConexion._CONX_MSJE_ERROR}</AlertDescription>
                      </Alert>)
                      }
                  </Stack>
                </form>
              </Box>
            </Stack>
          </Container>
        </Flex>
      </Container>
      </>
    )
  }

