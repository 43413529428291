import { Icon } from "@chakra-ui/react";
import {
    FiActivity,
    FiFileText,
    FiClock,
    FiHelpCircle,
    FiSettings,
    FiUsers,
    FiPower,
    FiRefreshCw,
    FiMail,
    FiUserPlus,
    FiFilePlus,
    FiDownload,
    FiUpload,
    FiRotateCcw,
    FiPaperclip,
  } from 'react-icons/fi'

  
export const IconResumen = () => (<Icon as={FiActivity} boxSize="6" color="teal" />)
export const IconPendientes = () => (<Icon as={FiClock} boxSize="6" color="teal" />)
export const IconContratos = () => (<Icon as={FiFileText} boxSize="6" color="teal" />)
export const IconAdjuntos = () => (<Icon as={FiPaperclip} boxSize="6" color="teal" />)
export const IconProveedores = () => (<Icon as={FiUsers} boxSize="6" color="teal" />)
export const IconAyuda = () => (<Icon as={FiHelpCircle} boxSize="6" color="subtle" />)
export const IconConfiguracion = () => (<Icon as={FiSettings} boxSize="6" color="subtle" />)
export const IconUsuarios = () => (<Icon as={FiUsers} boxSize="6" color="subtle" />)
export const IconSalir = () => (<Icon as={FiPower} boxSize="4" color="red" />)
export const IconRefrescar = () => (<Icon as={FiRefreshCw} boxSize="4" color="teal" />)
export const IconCorreo = () => (<Icon as={FiMail} boxSize="6" />)
export const IconNuevoContrato = () => (<Icon as={FiFilePlus} boxSize="6" color="purple" />)
export const IconNuevoProveedor = () => (<Icon as={FiUserPlus} boxSize="6" color="purple" />)
export const IconDescargar = () => (<Icon as={FiDownload} boxSize="6" color="blue" />)
export const IconSubir = () => (<Icon as={FiUpload} boxSize="6" color="blue" />)
export const IconDescargar4 = () => (<Icon as={FiDownload} boxSize="4" color="blue" />)
export const IconSubir4 = () => (<Icon as={FiUpload} boxSize="4" color="green" />)
export const IconDevolver = () => (<Icon as={FiRotateCcw} boxSize="4" color="red" />)