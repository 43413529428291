import { useFetch } from '../../hooks/useFetch';
import ContratosRspta from './ContratoConsRspta'
import { servContratosConsultar } from '../../utils/servicios';
import { IUseFetch } from '../../interfaces/iEndpoints';
import { useContext, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    VStack,
    useColorModeValue,
  } from '@chakra-ui/react'
import { K, KContratos, KEstado } from "../../utils/constantes";
import { AuthContext } from "../../context";

const ContratoCons = () => {

  const { usuario } = useContext( AuthContext );
  const [primeraVez, setPrimeravez] = useState(true);

  if (primeraVez) {
    servContratosConsultar.params = {
      ...servContratosConsultar.params,
      ctto_id: '',
      cges_id_list: '',
      esta_id_list: KEstado._FIRMA_2_OK,
      pnat_rut : usuario ? usuario.pnat_rut : '?',
      ctto_nombre: '',
      ctto_fecha_ini: '',
      ctto_fecha_fin: '',
    }
    setPrimeravez(false)
  }

  const contratosServ:IUseFetch = useFetch (servContratosConsultar, JSON.stringify ( servContratosConsultar.params ), 'ContratoCons');

  const bgColorC  = useColorModeValue("gray.200", "gray.900");
  const bgColorB  = useColorModeValue("white", "gray.700");
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);

  return (
    contratosServ.cargando
    ?
    (
        <Button isLoading={contratosServ.cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
    )
    :
    <Container 
            py={{ base: '1', md: '1' }} 
            px={{ base: '1', md: '1' }} 
            maxW='6xl' 
            p="3" 
            bg={ bgColorC }
          >
      <Flex 
        direction={{ base: 'column', lg: 'row' }}
        overflowY="auto"
        bg={ bgColorB }
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        borderRadius={{ base: 'none', sm: 'md' }}
      >

        <Box
            bg={ bgColorB }
            borderRadius={{ base: 'none', sm: 'md' }}
        >
          <VStack spacing="2" p='2'>

            <Heading size='md' p={3} color={colorTitulo}>{KContratos._C_TITULO}</Heading>

          </VStack>
        </Box>
      </Flex >

      <Container 
        maxW='12xl' 
        bg={ bgColorB }
        p='2'
      >
        <Box bg={ bgColorB }>
          <ContratosRspta respuesta={contratosServ} />
        </Box>
        <Divider />
        <Container bg={ bgColorB } p='40' />
      </Container>

    </Container>

  )
}

export default ContratoCons;