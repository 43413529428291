import { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth/AuthContext';
import { Link as LinkRouter, useNavigate  } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import ColorModeSwitcher from './ColorModeSwitcher'
import { IconAdjuntos, IconProveedores } from '../../utils/Icons';
import { 
  IconContratos, 
  IconCorreo, 
  IconPendientes, 
  IconSalir 
} from '../../utils/Icons';

const Sidebar = () => {

  const { usuario, logout } = useContext( AuthContext );
  const [opcion, setOpcion] = useState('b_panelcontrol');

  const history = useNavigate();

  const onclickMenu = (e: React.SyntheticEvent): void => {
    setOpcion(e.currentTarget.id);
  }
  
  const opActiva = (id:string, color:string):string => {
    const colorBG = (opcion === id) ? color : '';
    return(colorBG);
  }

  const onLogout = () => {
    logout();
    history('/conexion');
  }

  const logo = useColorModeValue("/logo_contratos_light.png", "/logo_contratos_dark.png");

  return(
    <Flex as="section" minH="100vh" bg={useColorModeValue("bg-surface", "blevas")} border="borderRight">
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        boxShadow={useColorModeValue('md', 'md-dark')}
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '2', sm: '2' }}
        px={{ base: '4', sm: '4' }}
      >
        <Stack >
          <Stack spacing={{ base: '1', sm: '1' }} >

            <Stack>
              <Image src={logo} alt="Contratos" p={2} w="200px;"/>
            </Stack>
        
            <Divider />

            <VStack spacing={{ base: '0', sm: '1' }} align="left" p="1">
  
              <LinkRouter to="/mis-pendientes">
                <Box bg={opActiva("x_mispendientes", useColorModeValue('gray.200', 'gray.600'))} rounded="md" p="1" id="x_mispendientes" onClick={onclickMenu}>
                  <Button leftIcon={<IconPendientes/>} variant="link" justifyContent="start" id="b_mispendientes" >Mis pendientes</Button>
                  </Box> 
              </LinkRouter>
 
              <LinkRouter to="/contratos">
                <Box bg={opActiva("x_contratos", useColorModeValue('gray.200', 'gray.600'))} rounded="md" p="1" id="x_contratos" onClick={onclickMenu}>
                  <Button leftIcon={<IconContratos/>} variant="link" justifyContent="start" id="b_contratos">Contratos firmados</Button>
                </Box> 
              </LinkRouter>
 
              <LinkRouter to="/adjuntos">
                <Box bg={opActiva("x_adjuntos", useColorModeValue('gray.200', 'gray.600'))} rounded="md" p="1" id="x_adjuntos" onClick={onclickMenu}>
                  <Button leftIcon={<IconAdjuntos/>} variant="link" justifyContent="start" id="b_adjuntos">Documentos adjuntos</Button>
                </Box> 
              </LinkRouter>

              <LinkRouter to="/datos-personales">
                <Box bg={opActiva("x_datos", useColorModeValue('gray.200', 'gray.600'))} rounded="md" p="1" id="x_datos" onClick={onclickMenu}>
                  <Button leftIcon={<IconProveedores/>} variant="link" justifyContent="start" id="b_datos">Datos personales</Button>
                </Box> 
              </LinkRouter>
              
  
            </VStack>

          </Stack>
          
          <VStack spacing={{ base: '0', sm: '0' }} align="left">

            <LinkRouter to="/soporte">
              <Box bg={opActiva("x_soporte", useColorModeValue('gray.200', 'gray.600'))} rounded="md" p="1" id="x_soporte" onClick={onclickMenu}>
                <Button leftIcon={<IconCorreo/>} variant="link" justifyContent="start" id="b_soporte" >Soporte</Button>
              </Box> 
            </LinkRouter>
           
          </VStack>

          <Box bg="bg-subtle" px="4" py="5" borderRadius="lg" alignItems={"flex-start"}>
            <Stack spacing="3">
                <Text fontSize="sm" fontWeight="medium">{`${usuario?.pnat_nombre}`}</Text>
                <HStack>
                <IconSalir/>
                <Link size="xs" fontSize="xs" colorScheme="blue" onClick={onLogout}>Salir</Link>
                </HStack>
            </Stack>
          </Box>

          <Divider />
          <ColorModeSwitcher/>
            
        </Stack>
      </Flex>
    </Flex>
  )
}

export default Sidebar;