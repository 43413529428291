import { useParams } from "react-router-dom"
import ContratoEditarTabs from "./ContratoEditarTabs";

const ContratoMant = () => {

  const params = useParams();

  return (
    (
      (!params || !params.id)
        ?
          null
        : 
          <ContratoEditarTabs id={parseInt( params.id)} />
    )
  )
}

export default ContratoMant;