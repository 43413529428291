import { useState, useContext } from 'react';
import { Box, Container, Divider, Flex, Heading, HStack, Link, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import AdjuntosForm from "./AdjuntosForm";
import AdjuntosConsulta from "./AdjuntosConsulta";
import { K, KPNatural } from '../../utils/constantes';
import { IconDescargar4 } from '../../utils/Icons';
import { TablasContext } from '../../context/TablasContext';
import { fParametros } from '../../utils/utilidades';
import { AuthContext } from '../../context';

const Adjuntos = () => {

  const { usuario, ambiente } = useContext( AuthContext );
  const [actualizar, setActualizar] = useState(0);
  const { tParametros } = useContext( TablasContext );

  const vParametros = fParametros(tParametros);

  const rut = usuario ? usuario.pnat_rut : '?';

  const bgColorC  = useColorModeValue("gray.200", "gray.900");
  const bgColorB  = useColorModeValue("white", "gray.700");
  const colorTitulo = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
  const colorLink = useColorModeValue("blue", "white");
 
  const d = new Date();
  const year = d.getFullYear();
  const pnat_url_decl_jurada = vParametros.pnat_url_decl_jurada.replace(/{{aaaa}}/g, year.toString() );

  return (
    <Container 
      py={{ base: '1', md: '1' }} 
      px={{ base: '1', md: '1' }} 
      maxW='6xl' 
      p="3" 
      bg={ bgColorC }
    >
      <Flex 
        direction={{ base: 'column', lg: 'row' }}
        overflowY="auto"
        bg={ bgColorB }
        py={{ base: '1', md: '2' }} 
        px={{ base: '1', md: '2' }} 
        borderRadius={{ base: 'none', sm: 'md' }}
      >

        <Box
            bg={ bgColorB }
            borderRadius={{ base: 'none', sm: 'md' }}
        >
          <VStack spacing="2" p='2'>
            <Heading size='md' p={3} color={colorTitulo}>{KPNatural._PN_AD_TITULO}</Heading>
          </VStack>
        </Box>
      </Flex >

      <Container 
        maxW='12xl' 
        bg={ bgColorB }
        p='2'
      >
        <Box bg={ bgColorB }>
          <VStack>
            <AdjuntosConsulta rut={rut} ambiente={ambiente} actualizar={actualizar} setActualizar={setActualizar} />
            <Divider />
            <AdjuntosForm rut={rut} ambiente={ambiente} actualizar={actualizar} setActualizar={setActualizar} />
            <Divider />
          </VStack>  
        </Box>
      </Container>

      <Container 
        maxW='12xl' 
        bg={ bgColorB }
        p='2'
      >
            <VStack alignContent={'left'}>
                <Text fontSize={'md'} fontWeight={'bold'} color={'gray'}>{'Declaración Jurada'}</Text>
                <Text fontSize={'md'} p={'4'}>{KPNatural._PN_AD_DECL_TEXTO}</Text>
                <HStack p={'1'}>
                  <IconDescargar4 />
                  <Link href={`${pnat_url_decl_jurada}`} isExternal color={colorLink}>{KPNatural._PN_AD_DECL_LINK}</Link>
                </HStack>
                  
            </VStack>
            <Divider height={5}/>
      </Container>


    </Container>
  )
}
export default Adjuntos;