import { FC, useContext } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { servPNaturalesConsultar, servPNaturalesModificar } from '../../utils/servicios';
import { IUseFetch, IPNaturalesModificar, IEndpoint, IPNaturalesConsultar } from '../../interfaces/iEndpoints';
import { useForm } from 'react-hook-form'
import {
    Box,
    Button,
    Container,
    Heading,
    useColorModeValue,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Divider,
    VStack,
    StackDivider,
    FormErrorMessage,
    Textarea,
    Select,
    useToast,
  } from '@chakra-ui/react'
import { esCorreoValido, formatRUT, esMayorEdad, pctjeMayusculas, fParametros } from '../../utils/utilidades';
import { useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { K, KV, KPNatural, KPerfiles } from '../../utils/constantes';
import { fetchTx } from '../../utils/fetchTx';
import { AuthContext } from '../../context/auth/AuthContext';
import { TablasContext } from '../../context/TablasContext';

interface Props {
  rut: string,
}

const PNaturalEditar: FC<Props> = () => {

  const { usuario, ambiente } = useContext( AuthContext );
  const { tEstadoCivil, tGenero, tBancos, tTipoCuenta, tPaises, tRegiones, tComunas, tParametros } = useContext( TablasContext );
  const toast = useToast();
  
  const [ primeraVez, setPrimeravez ] = useState(true);
  const [ mostrarMensajeError, setMostrarMensajeError ] = useState(false);
  const [ mostrarMensajeOK, setMostrarMensajeOK ] = useState(false);
  const [ mensajeActualizar, setMensajeActualizar ] = useState('');

  const { register, handleSubmit, getValues, setValue, trigger, formState: { errors, isSubmitting } } = useForm<IPNaturalesModificar>();

  const vParametros = fParametros(tParametros);

  const colorLabel        = useColorModeValue("blue.500", "whiteAlpha.800");
  const colorBG           = useColorModeValue("gray.50", "gray.700");
  const colorBG2          = useColorModeValue("gray.200", "gray.900")
  const colorBGErrores    = useColorModeValue("pink.100", "pink.200");
  const colorBGok         = useColorModeValue("green.100", "green.100");
  const colorTextMensajes  = useColorModeValue("gray.800", "gray.800");
  const bgInput           = useColorModeValue("white", "gray.600");
  const widthInput        = "40"; 
  const colorTitulo       = useColorModeValue(K._COLOR_TITULO_L, K._COLOR_TITULO_D);
  
  servPNaturalesConsultar.params = {
    ...servPNaturalesConsultar.params,
    pnat_rut : usuario?.pnat_rut,
    pnat_nombre: '',
    pnat_vigente: 'S'
  }

  const pNaturalesServ:IUseFetch = useFetch (servPNaturalesConsultar, JSON.stringify ( servPNaturalesConsultar.params ), 'PNaturales', false);

  const { cargando, data } = pNaturalesServ;
  const datos:IPNaturalesConsultar = data.data ? data.data[0] : [];

  const onSubmit = async ( data: IPNaturalesModificar ) => {

    servPNaturalesModificar.params = {
      pnat_nacional: K._SI,
      pnat_nombre1: data.pnat_nombre1,
      pnat_nombre2: data.pnat_nombre2,
      pnat_apellido1: data.pnat_apellido1,
      pnat_apellido2: data.pnat_apellido2,
      pnat_alias: data.pnat_alias,
      perf_id: KPerfiles._PERF_PNAT_USUARIO,
      pnat_correo: data.pnat_correo,
      pnat_telefono: data.pnat_telefono,
      pnat_profesion: data.pnat_profesion,
      pnat_genero: data.pnat_genero,
      pnat_fecha_nac: data.pnat_fecha_nac,
      naci_id: data.naci_id,
      eciv_id: data.eciv_id,
      pnat_direccion: data.pnat_direccion,
      pnat_direccion_compl: '',
      comu_id: data.comu_id,
      regi_id: data.regi_id,
      pais_id: '',
      pnat_tx_cuenta_banco: data.pnat_tx_cuenta_banco,
      pnat_tx_cuenta_nro: data.pnat_tx_cuenta_nro,
      pnat_tx_cuenta_tipo: data.pnat_tx_cuenta_tipo,
      pnat_cod_postal: '',
      pnat_decljurada_id: 0,
      pnat_decljurada_fecha: '1900-01-01',
      cges_id_default: data.cges_id_default,
      pnat_pers_id_insc: datos.pnat_pers_id_insc,
      pnat_vigente: K._SI,
      usua_id: usuario ? usuario.pnat_rut : '',
    }

    const resp:IEndpoint = await fetchTx (servPNaturalesModificar, usuario?.pnat_rut, ambiente);
    
    if (resp.code === 0) {
      const d = new Date()
      setMensajeActualizar(`${KPNatural._PN_MSJE_MODIF_EXITO}${d.toLocaleString()}`);
      setMostrarMensajeOK(true);
      setMostrarMensajeError(false);

      toast({
        title: KPNatural._PN_MSJE_TITULO_EXITO,
        description: KPNatural._PN_MSJE_MODIF_EXITO,
        status: 'success',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
    else {
      setMensajeActualizar(`${KPNatural._PN_MSJE_MODIF_FRACASO}\n\n${resp.data}`);
      setMostrarMensajeOK(false);
      setMostrarMensajeError(true);

      toast({
        title: KPNatural._PN_MSJE_TITULO_FRACASO,
        description: KPNatural._PN_MSJE_MODIF_FRACASO,
        status: 'error',
        duration: K._DURACION_TOAST_DEFAULT,
        isClosable: true,
      })
    }
  }

  return (
    cargando
    ?
    <Button isLoading={cargando} loadingText={K._CARGANDO} colorScheme='teal' variant='outline' />
    :
    <Container 
          py={{ base: '1', md: '1' }} 
          px={{ base: '1', md: '1' }} 
          maxW='8xl' 
          p="3" 
          bg={colorBG2}
          >
    <Flex 
      direction={{ base: 'column', lg: 'row' }}
      overflowY="auto"
      bg={colorBG}
      py={{ base: '1', md: '1' }} 
      px={{ base: '1', md: '1' }} 
      borderRadius={{ base: 'none', sm: 'md' }}
    >
      <Box
        bg={colorBG}
        w='3xl' 
      >
        <VStack spacing="0" p='2'>

          <Heading size='sm' p={3} color={colorTitulo}>{KPNatural._PN_TITU_EDITAR}</Heading>

          <Divider />

          <form onSubmit={ handleSubmit(onSubmit) } >

            <Stack spacing="3">

              { /* RUT ----------------------------------------------------------- */ }
              <FormControl>
                <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_RUT}</FormLabel>
                <Input 
                  bg={bgInput}
                  w={widthInput}
                  defaultValue={formatRUT(datos.pnat_pnat_rut)} 
                  readOnly={true}
                />
                <FormErrorMessage fontSize="10">
                  {errors.pnat_rut && errors.pnat_rut.message}
                </FormErrorMessage>
              </FormControl>

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                { /* NOMBRE1 ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_nombre1?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NOMBRE1}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={datos.pnat_pnat_nombre1} 
                    { ...register('pnat_nombre1', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 2, message: `${KV._LARGO_MIN} 2` },
                        maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_nombre1 && errors.pnat_nombre1.message}
                  </FormErrorMessage>
                </FormControl>

                { /* NOMBRE2 ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_nombre2?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NOMBRE2}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={datos.pnat_pnat_nombre2} 
                    { ...register('pnat_nombre2', 
                      { 
                        minLength: { value: 2, message: `${KV._LARGO_MIN} 2` },
                        maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_nombre2 && errors.pnat_nombre2.message}
                  </FormErrorMessage>
                </FormControl>

                { /* APELLIDO1 ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_apellido1?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_APELLIDO1}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={datos.pnat_pnat_apellido1} 
                    { ...register('pnat_apellido1', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 2, message: `${KV._LARGO_MIN} 2` },
                        maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_apellido1 && errors.pnat_apellido1.message}
                  </FormErrorMessage>
                </FormControl>

                { /* APELLIDO2 ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_apellido2?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_APELLIDO2}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={datos.pnat_pnat_apellido2} 
                    { ...register('pnat_apellido2', 
                      { 
                        maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_apellido2 && errors.pnat_apellido2.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              { /* <Divider /> */ }

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                { /* ALIAS ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_alias?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_ALIAS}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={60}
                    defaultValue={datos.pnat_pnat_alias} 
                    { ...register('pnat_alias', 
                      { 
                        maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_alias && errors.pnat_alias.message}
                  </FormErrorMessage>
                </FormControl>

                { /* CORREO ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_correo?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_CORREO}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={'md'}
                    defaultValue={datos.pnat_pnat_correo} 
                    { ...register('pnat_correo', 
                      { 
                        required: `${KV._CAMPO_OBL} ${KV._FORMATO_CORREO}`, 
                        minLength: { value: 5, message: `${KV._LARGO_MIN} 5` },
                        maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                        validate: {
                          forma: (v) => esCorreoValido(v) || KV._CORREO_INVALIDO,
                          abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS,
                        }
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_correo && errors.pnat_correo.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                { /* GENERO ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_genero?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_GENERO}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_pnat_genero} 
                    bg={bgInput}
                    {...register('pnat_genero', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_GENERO}`
                    },})} 
                  >
                    <option key='pnat_genero_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tGenero.map( genero => (
                        <option key={genero.tabl_tabl_elemento} value={genero.tabl_tabl_elemento} >
                          {genero.tabl_tabl_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_genero && errors.pnat_genero.message}
                  </FormErrorMessage>
                </FormControl>

                { /* TELEFONOS ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_telefono?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_TELEFONOS}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={'md'}
                    defaultValue={datos.pnat_pnat_telefono} 
                    { ...register('pnat_telefono', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 8, message: `${KV._LARGO_MIN} 8` },
                        maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_telefono && errors.pnat_telefono.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              { /* <Divider /> */ }

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                { /* FECHA_NAC ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_fecha_nac?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_FECHANAC}</FormLabel>
                  <Input 
                    type="date"
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={datos.pnat_pnat_fecha_nac} 
                    { ...register('pnat_fecha_nac', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 10, message: `${KV._LARGO_MIN} 10` },
                        maxLength: { value: 10, message: `${KV._LARGO_MAX} 10` },
                        validate: {
                          mayorEdad: (v) => esMayorEdad(v) || KV._MAYOR_18
                        }
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_fecha_nac && errors.pnat_fecha_nac.message}
                  </FormErrorMessage>
                </FormControl>

                { /* ESTADO_CIVIL ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.eciv_id?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_ECIVIL}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_eciv_id} 
                    bg={bgInput}
                    {...register('eciv_id', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_ECIVIL}`
                    },})} 
                  >
                    <option key='eciv_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tEstadoCivil.map( ecivil => (
                        <option key={ecivil.tabl_tabl_elemento} value={ecivil.tabl_tabl_elemento} >
                          {ecivil.tabl_tabl_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.eciv_id && errors.eciv_id.message}
                  </FormErrorMessage>
                </FormControl>

                { /* NACIONALIDAD ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.naci_id?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NACIONALIDAD}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_naci_id} 
                    bg={bgInput}
                    {...register('naci_id', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_NACIONALIDAD}`
                    },})} 
                  >
                    <option key='naci_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tPaises.map( pais => (
                        <option key={pais.tabl_tabl_elemento} value={pais.tabl_tabl_elemento} >
                          {pais.tabl_tabl_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.naci_id && errors.naci_id.message}
                  </FormErrorMessage>
                </FormControl>

                { /* PROFESION ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_profesion?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_PROFESION}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={widthInput}
                    defaultValue={datos.pnat_pnat_profesion} 
                    { ...register('pnat_profesion', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 5, message: `${KV._LARGO_MIN} 5` },
                        maxLength: { value: 100, message: `${KV._LARGO_MAX} 100` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_profesion && errors.pnat_profesion.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              { /* <Divider /> */ }

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                { /* DIRECCION ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_direccion?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_DIRECCION}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={'100%'}
                    defaultValue={datos.pnat_pnat_direccion} 
                    { ...register('pnat_direccion', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 10, message: `${KV._LARGO_MIN} 10` },
                        maxLength: { value: 200, message: `${KV._LARGO_MAX} 200` },
                        validate: { abuso: (v) => pctjeMayusculas (v, vParametros.ctto_pcjte_mayusculas) || KV._MSJE_PCTJE_MAYUSCULAS},
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_direccion && errors.pnat_direccion.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >
                { /* REGION ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.regi_id?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_REGION}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_regi_id} 
                    bg={bgInput}
                    {...register('regi_id', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_REGION}`
                    },})} 
                    onChange={() => setValue('comu_id', K._SELE_ID)}
                  >
                    <option key='regi_id' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tRegiones.map( region => (
                        <option key={region.tabl_tabl_elemento} value={region.tabl_tabl_elemento} >
                          {region.tabl_tabl_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.regi_id && errors.regi_id.message}
                  </FormErrorMessage>
                </FormControl>

                { /* COMUNA ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.comu_id?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_COMUNA}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_comu_id} 
                    bg={bgInput}
                    {...register('comu_id', { 
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_COMUNA}`
                    },})} 
                    onClick={async () => {
                      await trigger(["regi_id"]);
                      setPrimeravez(false);
                    }}
                  >
                    <option key='comu_id_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tComunas.map( comuna => {
                        if ( ( comuna.tabl_tabl_elemento_padre === getValues('regi_id') ) || (primeraVez && ( comuna.tabl_tabl_elemento_padre === datos.pnat_regi_id ) ) )
                          return(<option key={comuna.tabl_tabl_elemento} value={comuna.tabl_tabl_elemento} >{comuna.tabl_tabl_nombre}</option>)
                        else
                          return null
                      })
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.comu_id && errors.comu_id.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              { /* <Divider /> */ }

              <Stack spacing="3" direction={{ base: 'column', md: 'row' }} divider={<StackDivider />} >

                { /* BANCO ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_tx_cuenta_banco?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_BANCO}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_pnat_tx_cuenta_banco} 
                    bg={bgInput}
                    {...register('pnat_tx_cuenta_banco', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_BANCO}`
                    },})} 
                  >
                    <option key='pnat_tx_cuenta_banco_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tBancos.map( banco => (
                        <option key={banco.tabl_tabl_elemento} value={banco.tabl_tabl_elemento} >
                          {banco.tabl_tabl_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_tx_cuenta_banco && errors.pnat_tx_cuenta_banco.message}
                  </FormErrorMessage>
                </FormControl>

                { /* NRO_CUENTA ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_tx_cuenta_nro?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_NROCUENTA}</FormLabel>
                  <Input 
                    bg={bgInput}
                    w={'50'}
                    defaultValue={datos.pnat_pnat_tx_cuenta_nro} 
                    { ...register('pnat_tx_cuenta_nro', 
                      { 
                        required: KV._CAMPO_OBL, 
                        minLength: { value: 4, message: `${KV._LARGO_MIN} 4` },
                        maxLength: { value: 30, message: `${KV._LARGO_MAX} 30` },
                      })
                    } 
                  />
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_tx_cuenta_nro && errors.pnat_tx_cuenta_nro.message}
                  </FormErrorMessage>
                </FormControl>

                { /* TIPO_CUENTA ----------------------------------------------------------- */ }
                <FormControl isInvalid={errors.pnat_tx_cuenta_tipo?true:false}>
                  <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_TIPOCUENTA}</FormLabel>
                  <Select 
                    size={'md'} 
                    defaultValue={datos.pnat_pnat_tx_cuenta_tipo} 
                    bg={bgInput}
                    {...register('pnat_tx_cuenta_tipo', {
                      validate: {
                        seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_TIPOCUENTA}`
                    },})} 
                    
                    >
                    <option key='pnat_tx_cuenta_tipo_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                    {
                      tTipoCuenta.map( tipo => (
                        <option key={tipo.tabl_tabl_elemento} value={tipo.tabl_tabl_elemento} >
                          {tipo.tabl_tabl_nombre}
                        </option>
                      ))
                    }  
                  </Select>
                  <FormErrorMessage fontSize="10">
                    {errors.pnat_tx_cuenta_tipo && errors.pnat_tx_cuenta_tipo.message}
                  </FormErrorMessage>
                </FormControl>

              </Stack>

              { /* CENTRO_GESTION ----------------------------------------------------------- */ }
              {/*              
              <FormControl isInvalid={errors.cges_id_default?true:false}>
                <FormLabel fontSize="xs" color={colorLabel}>{KPNatural._PN_CGESTION}</FormLabel>
                <Select 
                  size={'md'} 
                  defaultValue={datos.pnat_cges_id_default} 
                  bg={bgInput}
                  {...register('cges_id_default', {
                    validate: {
                      seleccionar: (c) => (c !== K._SELE_ID) || `${KV._MSJE_SELECCIONE} ${KPNatural._PN_CGESTION}`
                  },})} 
                >
                  <option key='cges_id_default_s' value={K._SELE_ID}>{`${K._SELE_TEXTO}`}</option>
                  {
                    tCentrosGestion.map( cgestion => (
                      <option key={cgestion.cges_cges_id} value={cgestion.cges_cges_id} >
                        {cgestion.cges_cges_nombre}
                      </option>
                    ))
                  }  
                </Select>
                <FormErrorMessage fontSize="10">
                  {errors.cges_id_default && errors.cges_id_default.message}
                </FormErrorMessage>
              </FormControl>
              */}
              <FormControl>
                <Input 
                  defaultValue={datos.pnat_cges_id_default} 
                  w="xl"
                  type="hidden"
                  { ...register('cges_id_default',{ } )} 
                />
              </FormControl>

              <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button colorScheme='teal' variant='outline' type="submit" isLoading={isSubmitting}>
                    {KPNatural._PN_BOTON_MODIFICAR}
                </Button>
              </Flex>

              {
                <>
                  { mostrarMensajeOK && <Textarea readOnly={true} bg={colorBGok} color={colorTextMensajes} value={`${mensajeActualizar}`} /> }
                  { mostrarMensajeError && <Textarea isInvalid readOnly={true} bg={colorBGErrores} color={colorTextMensajes} value={`${mensajeActualizar}`} h={120}/> }
                </>
              }

            </Stack>

          </form>
        </VStack>
      </Box>
    </Flex>
  </Container>
)
}

export default PNaturalEditar;